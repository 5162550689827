.menu-language {
}

.menu-language-box:hover + .menu-language:hover {
  border-top: none;
  top: 0px;
  height: 300px;
}

.menu-language-box:hover {
  height: 300px;
}

#some-element {
  border: 1px solid #ccc;
  display: none;
  font-size: 10px;
  margin-top: 10px;
  padding: 5px;
  text-transform: uppercase;
}

#some-div:hover #some-element {
  display: block;
}
.text-main-black {
  color: $primary-color;
}
