@for $i from 1 through 50 {
  .fs- {
    &#{$i} {
      font-size: #{$i}px;
    }
    @media (max-width: 576px) {
      &xs-#{$i} {
        font-size: #{$i}px !important;
      }
    }

    // Medium devices (tablets, 768px and up)
    // @media (min-width: 768px) {
    //   &sm-#{$i} {
    //     font-size: #{$i}px;
    //   }
    // }

    // // Large devices (desktops, 992px and up)
    // @media (min-width: 992px) {
    // }
    // &md-#{$i} {
    //   font-size: #{$i}px;
    // }

    // // Extra large devices (large desktops, 1200px and up)
    // @media (min-width: 1200px) {
    //   &lg-#{$i} {
    //     font-size: #{$i}px;
    //   }
    // }
  }
  .mrc-#{$i} {
    margin-right: #{$i}px;
  }
  .mlc-#{$i} {
    margin-left: #{$i}px;
  }
  .mtc-#{$i} {
    margin-top: #{$i}px;
  }
  .mbc-#{$i} {
    margin-bottom: #{$i}px;
  }

  .ptc-#{$i} {
    padding-top: #{$i}px;
  }
  .prc-#{$i} {
    padding-right: #{$i}px;
  }
  .pbc-#{$i} {
    padding-bottom: #{$i}px;
  }
  .plc-#{$i} {
    padding-left: #{$i}px;
  }
  .br-#{$i} {
    border-radius: #{$i}px;
  }
  .btm-#{$i} {
    bottom: #{$i}px;
  }
  .tp-#{$i} {
    top: #{$i}px;
  }
}
