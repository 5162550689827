@import "./variable";
.newitems {
  width: 40px;
  height: 16px;
  border-radius: 5px;
  background-color: red;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;

  line-height: 1;
  text-align: center;
  background: linear-gradient(
    315deg,
    red 0%,
    red 20%,

    #fef1a2 50%,
    red 70%,
    red 80%,
    red 100%
  );

  animation: shine 4s linear infinite;
  background-size: 200%;
  background-position: left;
}
@keyframes shine {
  0% {
    background-position: 150% 0;
  }
  100% {
    background-position: -50% 0;
  }
}
.badge-check {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: $badge-disabled;
  &.success {
    background: $primary-color;
  }
}
