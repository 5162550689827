.stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.stepper-item {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    font-size: 12px;
  }
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2;

  @media (max-width: 768px) {
    width: 100%;
    left: -50%;
  }
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 2;
  @media (max-width: 768px) {
    width: 100%;
    left: 50%;
  }
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  margin-bottom: 6px;
  color: rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.stepper-item.active {
  font-weight: bold;
}

.stepper-item.completed .step-counter {
  border: 1px solid #1cb894;
  background-color: #1cb894;
  color: #fff;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";

  border-bottom: 2px solid #1cb894;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 3;
}
.stepper-item.completed::before {
  position: absolute;
  content: "";

  border-bottom: 2px solid #1cb894;
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 3;
}
.step-name {
  padding: 8px;
  background: #fff;
  z-index: 5;
  margin-bottom: 6px;
  @media (max-width: 768px) {
    text-align: center;
    white-space: pre-line;
    margin: 0;
  }
}
.step-name.active {
  color: #000 !important;
}
.stepper-item.completed .step-name {
  color: #1cb894;
}
