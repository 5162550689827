.selected {
  background-color: #0162ac !important;
  color: white !important;
}

.ant-table-thead
  > tr
  > th.ant-table-column-has-actions.ant-table-column-has-sorters,
.ant-table-thead > tr > th {
  background-color: #fff;
  color: black;
}

table {
  border: 1px;
}

.deposit-select {
  /* border: 2px solid transparent !important; */
  cursor: pointer;
  height: 140px;

  border: 1px solid #f2f2f2;
  border-radius: 12px;
  padding: 16px;
  text-align: center;
}
.deposit-select-2 {
  border: 2px solid transparent !important;
  cursor: pointer;
  height: 240px;
  box-shadow: 0 0 12px #8080803b;
  border: none;
  border-radius: 6px;
  padding: 16px;
  text-align: center;
}

.deposit-select:hover {
  border: 2px solid #1cb894 !important;
  transition: none;
}
.account-button:hover {
  cursor: pointer;
  color: #ffff;
  background-color: #0162ac;
}
.f-16res {
  font-size: 16px;
  text-align: center;
}

.text-header-deposit {
  font-weight: 700;
  color: #000;
  /* font-family: sans-serif; */
  font-size: 14px;
  text-align: center;
  padding: 6px;
}
.img-payment {
  width: 100px;
  height: auto;
}

@media only screen and (max-device-width: 1024px) {
  .f-16res {
    font-size: 16px;
  }

  .auto-display {
    display: flex;
  }

  .col-payment {
    min-width: 100px;
    max-width: 100px;
  }

  .img-payment {
    width: 80px;
    padding-top: 20px;
  }
}

@media only screen and (max-device-width: 480px) {
  .auto-display {
    display: flex;
  }

  .col-payment {
    min-width: 100px;
    max-width: 100px;
  }

  .img-payment {
    width: 70px;
    padding-top: 24px;
  }

  .text-header-deposit {
    font-weight: 700;
    /* font-family: sans-serif; */
    font-size: 11px;
    text-align: center;
    padding: 6px;
  }
}
