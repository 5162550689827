@import "~antd/dist/antd.css";
@import "./assets/css/index.css";
@import "./assets/scss/_font.scss";
@import "./assets/scss/_verifyuser.scss";
@import "./assets/scss/_steps.scss";

body {
  font-family: "Inter" !important;
}

#app {
  background: white !important;
}

.spinner {
  display: inline-block;
  opacity: 0;
  max-width: 0;
  -webkit-transition: opacity 0.25s, max-width 0.45s;
  -moz-transition: opacity 0.25s, max-width 0.45s;
  -o-transition: opacity 0.25s, max-width 0.45s;
  transition: opacity 0.25s, max-width 0.45s;
  /* Duration fixed since we animate additional hidden width */
}
.container-text-center {
  max-width: 680px;
}
.bg-section-dark {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(23, 23, 23, 1) 100%
  );
}
.bg-line {
  background-image: url("/public/assets/images/covers/bg-line.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 480px;
}
.has-spinner.active {
  cursor: progress;
}

.notransition {
  -webkit-transition: 0ms !important;
  -moz-transition: 0ms !important;
  -o-transition: 0ms !important;
  transition: 0ms !important;
}

.pl_pr_8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.w-md-50-w-sm-100 {
  width: 50%;
}


.has-spinner.active .spinner {
  opacity: 1;
  max-width: 50px;
  /* More than it will ever come, notice that this affects on animation duration */
}

.landingPage-btn-dowload-mt4 {
  cursor: pointer;
  display: inline-block;
  padding: 10px 30px;
  background-color: #0162ac;
  color: #fff;
  border-radius: 6px;
  font-size: 18px;
  font-family: sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}

.star-account-type {
  display: inline-block;
  position: absolute;
  margin-top: 17px;
  margin-left: 1px;
}
.accounts-logo {
  padding: 5px 15px;
  
  color: #fff;
  border-radius: 6px;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  font-family: sans-serif;
  margin: auto;
}
.std-account-logo {
  padding: 5px 15px;
  background: #039fff;
  color: #fff;
  border-radius: 6px;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  font-family: sans-serif;
  margin: auto;
}

.stdb-account-logo {
  /* padding: 5px 15px; */
  width: 40px;
  height: 40px;
  background: #0162ac;
  color: #fff;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 700;
  display: flex;
  font-family: sans-serif;
  margin: auto;
}

.ecn-account-logo {
  /* padding: 5px 15px; */
  width: 40px;
  height: 40px;
  background: #28a141;
  color: #fff;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 700;
  display: flex;
  font-family: sans-serif;
  margin: auto;
}

.box-banner {
  height: 90px;
}

.pro-account-logo {
  /* padding: 5px 15px; */
  width: 40px;
  height: 40px;
  background: #1cb894;
  color: #fff;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 700;
  display: flex;
  font-family: sans-serif;
  margin: auto;
}

.demo-account-logo {
  /* padding: 5px 15px; */
  width: 40px;
  height: 40px;
  background: #ff6c00;
  color: #fff;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 700;
  display: flex;
  font-family: sans-serif;
  margin: auto;
}

.banner-dowload-ios-an {
  display: flex;
  position: absolute;
  margin-top: 21px;
  margin-left: 19px;
  width: 141px;
  justify-content: space-between;
}

.pb-pt-textCover {
  padding-top: 15%;
  padding-bottom: 10%;
}

.border-loyalty-landingpage {
  border: 1px solid black;
}

.col-md-landingPage {
  min-width: auto;
  margin-right: 20px;
}

.landingPage-footerSelection {
  /* padding: 5% 15% 0px; */
  /*background-color: rgb(34, 34, 34);*/
  background-image: radial-gradient(
    circle,
    rgb(29, 57, 74),
    rgb(26, 50, 62),
    rgb(25, 43, 51),
    rgb(23, 36, 41),
    rgb(22, 29, 31)
  );
}

.landingPage-flex-block {
  display: flex !important;
}

.landingPage-footer-iconSocial {
  /* background-color: black; */
}

.landing-float-RL {
  float: right;
}

.lading-icon-socail {
  min-width: 40px;
  padding-top: 0px;
  padding-bottom: 0px;
  text-align: center;
}

.forex-style-Font {
  /* font-family: sans-serif; */
  font-weight: 700;
  text-align: center;
  color: #03588c;
}

.content {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  padding: 0px;
  height: 100%;
}

.card-banner {
  width: auto;
  margin: 8px !important;
  display: flex;
  justify-content: center;
}

.banner-style {
  border-radius: 40px !important;
  border: 2px solid white !important;
  background-color: #f0f8ff0d !important;
}

.bonustext-banner {
  font-size: 24px;
  color: white;
  padding: 8px;
  font-weight: bold;
  text-align: center;
}

.bunusvalue-banner {
  font-size: 24px;
  padding: 8px;
  color: #fff;
  font-weight: bold;
}

.banner-titleheader {
  font-size: 44px;
  white-space: pre-line;
  text-align: left;
  font-weight: 700 !important;
}

.landing-header {
  padding: 0rem 70px 0px 0px;
}

.ant-input-prefix {
  display: flex;
  align-items: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.bg-yellow-iux{
background: rgb(241, 158, 3);
}
.bg-primary-iux{
 background:  #1cb894;
}
.bg-danger{
  background: #B44844;
}
.bg-banner {
  background-color: rgba(0, 0, 0, 0.46);
}
.bg-black {
  background-color: #000 !important;
}

.bg-section {
  background-color: rgb(0 206 255 / 5%);
}
.bg-gray {
  background-color: #000 !important;
}
.bg-dark-gray {
  background-color: #242424 !important;
}

.div-transform {
  /* position: absolute; */
  background: #242424;
  z-index: 1;
  /* right: -60px; */
  margin-top: 10px;
  width: 268px;
  /* transform: skew(-6deg); */
  box-shadow: 0 0 19px 10px #2ab8ff14;
  height: auto;
  border-radius: 20px;
}
.card-partner-transform {
  background: #242424;
  z-index: 1;

  margin-top: 10px;
  width: 380px;

  box-shadow: 0 0 19px 10px #2ab8ff14;
  height: 500px;
  border-radius: 20px;
}
.bg-yellow-partner {
  background-color: #dcbe59;
}

.subbanner-bottom {
  background-color: rgba(0, 0, 0, 0.46);
  padding: 16px;
  text-align: center;
  position: relative;
  z-index: 100000000000;
  /* top: 83%; */
  width: 100%;
}

.banner-bonus {
  margin: 0px 0rem 10rem 0px;
}

.carousel-indicators .active {
  opacity: 0;
}

.btn-free30 {
  background-color: #1cb880 !important;
  /* margin-top: 30px !important; */
  padding: 14px 20px !important;
  color: aliceblue !important;
  border-radius: 6px !important;
  border: 0px !important;
  text-transform: uppercase;
  box-shadow: 1px 3px 5px 0px #00000036;
  /* width: 100% !important; */
  border: 1px solid #1cb880 !important;
  font-family: sans-serif !important;
}
.btn-free30:hover {
  border: 1px solid #1cb880 !important;
  color: #1cb880 !important;
  background-color: #000 !important;
}
.btn-free30:focus {
  border: 1px solid #1cb880 !important;
  color: #1cb880 !important;
  background-color: #000 !important;
  outline:unset;
}

.btn-standardx {
  background-color: #039fff !important;
  margin-top: 30px !important;
  padding: 14px 20px !important;
  color: aliceblue !important;
  border-radius: 6px !important;
  border: 0px !important;
  text-transform: uppercase;
  box-shadow: 1px 3px 5px 0px #00000036;
  width: 100% !important;
  font-family: sans-serif !important;
}

.max-wheader {
  max-width: 660px;
}

.f-12 {
  font-size: 10px;
  font-family: revert;
}

.mb-40 {
  margin-bottom: 15px;
}
.button-dowloadqrcode {
  display: inline-block;
  padding: 10px 20px;
  color: white;
  background-color: #0162ac;
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.button-copy {
  padding: 4px 18px;
  background-color: #0162ac;
  color: white;
  border-radius: 3px;
}

.ant-dropdown-placement-bottomLeft {
  /* width: 100% !important; */
  min-width: 100px;
  left: 0px;
  top: 70px;
  max-width: 100px;
}

a {
  color: #93b5ce;
}

.ant-table {
  overflow: auto;
}

.ant-col {
  position: relative;
  max-width: 100% !important;
  min-height: 1px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px !important;
  line-height: inherit;
}

/* p {
  text-align: justify;
} */

span > i.anticon {
  vertical-align: middle;
}

.iux-icon-panal-bg {
  background: #fff;
  margin: 4% 6px 4% 4px;
}

.expandable {
  background: #fff;
  overflow: hidden;
  color: #000;
  line-height: 50px;
  transition: all 0.5s ease-in-out;
  height: 0;
}

.expandable {
  height: 50px;
}

.background-section-platform {
  background: #000;
}
.style-platform-all {
  font-size: 28px;
  font-weight: 700;
  font-family: sans-serif;
  margin-top: 25px !important;
}

.is-active {
  color: green !important;
}

.img-platform-all-responsive {
  width: 308px;
  height: 210px;
  object-fit: scale-down;
  position: relative;
}

.margin-description-platform {
  margin: 4rem 0;
}

.read-more-button {
  color: #0162ac;
}

.rectangle-card {
  padding: 40px 4%;
  border-radius: 20px;
  background-color: #242424;
  max-width: 500px;
  opacity: 1;
  display: inline-block;
  width: 100%;
}

.rectangle-card-partner {
  margin: 2.6%;
  padding: 25px 5%;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #242424;
  max-width: 650px;
  opacity: 1;
  display: inline-block;
  width: 100%;
}

.rectangle-card-login {
  padding: 40px 4%;
  max-width: 500px;
  height: auto;
  /* border: 1px solid #e7e7e7; */
}

.btn-login-landingpage {
  border-radius: 2px !important;
}

.ant-modal-wrap {
  display: flex;
}

.width-modal-responsive {
  width: 55% !important;
}

.rectangle-card-reset {
  padding: 53px 5%;
  border-radius: 5px;
  background-color: #ffffff;
  max-width: 560px;
  max-height: 900px;
  opacity: 1;
  display: inline-block;
  width: 100%;
  border: 1px solid #e7e7e7;
}

.requirement-title {
  /*padding-bottom: 1.5rem !important;*/
  float: left;
  max-width: 600px;
  margin-left: 15%;
  padding-bottom: 0 !important;
}

.ant-drawer-body {
  padding: 0;
}

.ant-layout-header {
  background-color: #fff !important;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item,
.ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu {
  display: inline-block;
  width: 100%;
}

.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover {
  border-bottom-color: transparent;
}

.ant-menu-item:hover {
  border-bottom: 2px solid transparent !important;
}

.ant-menu-item-selected {
  border-bottom: 2px solid transparent !important;
}

.menu-hover-item:hover {
  background-color: #171717;
}

.clickable {
  cursor: pointer;
}

.ant-input-affix-wrapper,
.ant-select-enabled {
  width: 100% !important;
}

.img-mt5-landing-page {
  position: absolute;
  right: -8%;
  margin-top: -14%;
}

.p-70-sm-20 {
  padding: 40px 70px 40px 70px;
}

.w-responsive-img-mt-5 {
  width: 650px;
}

.w-responsive-img-mt-5-promo {
  width: 550px;
}

.width-lg-50-sm-100 {
  width: 50%;
}

.f-56 {
  font-size: 48px;
}

.f-40 {
  font-size: 35px;
}

.f-32 {
  font-size: 26px;
}
.f-42 {
  font-size: 42px;
}
.h-bg-cover-mt-5 {
  height: 380px;
}

.h-bg-cover-mt-5 {
  height: 400px;
}

.ant-radio-wrapper {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 0.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum", "tnum";
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  margin-right: 8px;
  white-space: nowrap;
  cursor: pointer;
}
.payment .ant-radio-wrapper {
  display: flex;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 0.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum", "tnum";
  font-feature-settings: "tnum", "tnum";
  position: relative;
  margin-right: 8px;
  white-space: nowrap;
  cursor: pointer;
}

.text-in-cover {
  padding-left: 0 !important;
}

.text-in-cover-text-video {
  padding-left: 14%;
}

.margin-card-img-deposit {
  margin: auto;
  width: 400px;
  display: inline-block;
  text-align: center;
}

.icon-panal-img-partner {
  width: 80px;
  height: auto;
}
.icon-panal-partner {
  margin: 0;
  width: 150px;
  display: inline-block;
  min-height: 220px;
}

.minWallet-w100 {
  min-width: 100px;
}
.text-in-cover-video {
  padding-top: 35.12%;
  padding-bottom: 10%;
  width: 100%;
}

/* .ant-table-pagination.ant-pagination {
  margin: 16px 0;
  opacity: 0;
  position: absolute;
} */

.resolution-display {
  padding: 90pt 0;
}

.deposit-button {
  padding: 0% 0%;
  margin-top: 20px;
}

.windows-button {
  border-radius: 24px;
  color: #fff;
  background-image: linear-gradient(
    to right top,
    #009eff,
    #00abf4,
    #00b3da,
    #00b7b7,
    #1cb894
  );
  padding: 12px 12px;
  border: none;
}

.android-button {
  padding: 0% 0%;
  margin-top: 20px;
}

.iphone-ipad-button {
  padding: 0% 0%;
  margin-top: 20px;
}

.partner-button {
  padding: 0% 0%;
  margin-top: 20px;
}

.trading-program {
  margin: 16px;
  width: 350px;
  /* box-shadow: 0px 0px 5px 0px rgba(150, 150, 150, 0.45); */
}

.col-iux {
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.hr-iux {
  border-top: 1px dashed #a5a5a5;
}

.top-typeiux {
  padding-top: 5px;
  padding-bottom: 5px;
}

.bottom-learnmore {
  padding-top: 30px;
  padding-bottom: 20px;
}

.color-textbox {
  color: #03588c;
}

.blog-iuxRegis {
  padding: 6px 0px 6px 0px;
  font-size: 12px;
}

.card-typeIux {
  margin: 29.6% 0;
}

.min-150 {
  min-width: 150px;
}

.type-iux {
  height: auto;
  width: 44px;
  border-radius: 5px;
}

.bg-typeiux {
  background-color: #f2f2f2;
  /* border-bottom: 4px solid #03588C; */
  margin-left: 6px;
  border-radius: 5px;
  margin-top: 12px;
}

.box-market-landingpage {
  width: 100%;
}

.trading-iphone-programe {
  margin-bottom: 22px;
}
.p-30 {
  padding: 30px;
}
.p-t-70 {
  padding-top: 70px !important;
}
.p-t-40 {
  padding-top: 40px !important;
}
.p-t-0 {
  padding-top: 0px !important;
}
.p-7 {
  padding: 3rem 7rem !important;
}
.trading-program-content {
  background: rgb(255, 255, 255);
  padding: 24px;
  margin-top: 24px;
  border-radius: 5px;
}

.ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title > a {
  display: block;
  margin: -5px -12px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.65);
  -webkit-transition: all 0.3s;
  transition: all 0.1s;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.65) !important;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.1s;
}
.ant-dropdown-menu-item-active > a:hover,
.ant-dropdown-menu-submenu-title > a {
  color: black !important;
  /* background-color: none !important; */
}
.promotion-border {
  padding: 45px 0px 0px 50px;
  border-left: 0px solid #0162ac;
}

.promotion-text {
  font-weight: 700;
  font-size: 72px;
  text-align: center;
  color: rgb(161, 161, 161);
}

.iux-block {
  /* border: 1px solid rgb(60 160 255); */
  background-color: #242424;
  max-width: 100%;
  border-radius: 9px;
  padding: 50px 50px 60px;
  height: 100%;
  font-size: 16px;
}

.plateform-btn {
  position: absolute;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 0;
  bottom: 80px;
  border-radius: 16px;
}

.f-40 {
  font-size: 40px;
}

.card-iux-trade {
  background: #242424;
  /* box-shadow: 0px 0px 12px 0px #a7d3ff80; */
  border-radius: 20px;
  height: 205px;
  padding: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mt-40 {
  margin-top: 40px;
}

.f-20 {
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  font-family: sans-serif;
}
.f-16 {
  font-size: 16px;
}
.iux-matatrader-link {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.btn-hover:hover {
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.46);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.panel__heading {
  max-width: 770px;
  width: 100%;
  margin: 0 auto 3.7rem;
}

.iux-platform-img {
  width: auto;
}
.cover-responsive-800 {
  /* padding-top: 70px; */
  height: 800px;
}

.cover-responsive {
  /* padding-top: 70px; */
  height: 480px;
}

/* scroll */
#wrapper {
  position: absolute;
  top: 90vh;
  cursor: pointer;
}

#wrapper-inner {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

#scroll-down {
  display: block;
  position: relative;
  padding-top: 33px;
  text-align: center;
}
.arrow-down {
  display: block;
  margin: 0 auto;
  width: 9px;
  height: 39px;
}
.arrow-down:after {
  content: "";
  display: block;
  margin: 0;
  padding: 0;
  width: 8px;
  height: 8px;
  border-top: 2px solid gray;
  border-right: 2px solid gray;
  /* behavior: url(-ms-transform.htc); */
  -moz-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}
#scroll-title {
  display: block;
  text-transform: uppercase;
  color: gray;
  font-family: Helvetica Neue, Helvetica, Arial;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.1em;
}
#scroll-down::before {
  -webkit-animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;
  /* Safari 4+ */

  -moz-animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;
  /* Fx 5+ */

  -o-animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;
  /* Opera 12+ */

  animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;
  /* IE 10+, Fx 29+ */

  position: absolute;
  top: 0px;
  left: 50%;
  margin-left: -1px;
  width: 2px;
  height: 39px;
  background: gray;
  content: " ";
}
@-webkit-keyframes elasticus {
  0% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
  50% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50.1% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
}
@-moz-keyframes elasticus {
  0% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
  50% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50.1% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
}
@-o-keyframes elasticus {
  0% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
  50% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50.1% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
}
@keyframes elasticus {
  0% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
  50% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50.1% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
}

#paschka {
  display: block;
  color: white;
  font-family: helvetica neue, helvetica, arial;
  font-size: 32px;
  text-decoration: none;
  position: fixed;
  bottom: 0px;
  right: 0px;
  padding: 0px;
  transition: all 0.1s ease;
  background: #000;
  height: 40px;
  line-height: 30px;
  vertical-align: middle;
  width: 40px;
  text-align: center;
  border-radius: 5%;
  bottom: 20px;
  right: 20px;
}
/* end scroll */
.iux-block {
  /* border: 1px solid rgb(60 160 255); */
  border-radius: 9px;
  padding: 50px 50px 60px;
  height: 100%;
  font-size: 16px;
}
.iux-contactsocial {
  width: auto;
  height: 20px;
}

.f-right {
  float: right;
}

.bg-iux {
  background-color: #f2f2f2;
}

.iux-advisa {
  float: right;
}

.box-content-market {
  border: 1px solid #06a0ff;
  border-radius: 12px;
  padding: 3rem 0px;
}
.ant-modal-content-addcard {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  width: 700px !important;
}

.ant-modal {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum", "tnum";
  font-feature-settings: "tnum", "tnum";
  position: relative;
  top: 100px;
  width: auto;
  margin: 0 auto;
  padding-bottom: 0px !important;
  pointer-events: none;
}

.carousel-caption {
  position: absolute;
  right: 11%;
  bottom: 20px;
  left: 11%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 0px;
  color: #fff;
  text-align: center;
  width: 100% !important;
  top: 16%;
}

.d-unset {
  display: inline-block !important;
}

.h1-responsive24 {
  font-size: 32px;
  font-weight: 700;

  color: #fff;
}
.h1-responsive24:hover {
  font-size: 32px;
  font-weight: 700;

  color: #1cb880;
}

.text-color-iux {
  color: #1cb880 !important;
}

.text-leftresponsive {
  text-align: left !important;
  font-family: sans-serif;
  font-weight: 700;
}
.font-bold-iux {
  font-family: sans-serif;
  font-weight: 700;
}
.text-cneterresponsive-content {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  /* width: 600px; */
  /* margin: auto !important; */
}
.text-leftresponsive-content {
  font-size: 14px;
  font-weight: 500;

  width: 600px;
  /* margin: auto !important; */
}
.text-righttresponsive {
  text-align: right !important;
}



.main-curs {
  height: 70vh;
}

.coverbottom-banner {
  background-color: rgba(10, 10, 10, 0.55);
}

.banner-section {
  background-color: rgba(10, 10, 10, 0.55);
  padding: 16px;
  text-align: center;
  position: absolute;
  top: 85%;
  min-width: 100%;
}

.height-100vh {
  height: 100vh;
}

.bt-buttonFootter {
  background-color: rgb(40, 161, 65) !important;
  border-radius: 2px;
  outline: none !important;
  margin-top: 16px;
  padding: 10px 30px;
  height: auto;
  max-width: fit-content;
  color: #fff !important;
  border: 0px solid !important;
}

.inputp-register {
  background-color: #e9e9e9;
}
.bt-buttonFootter2 {
  background-color: rgba(40, 161, 64, 0) !important;
  border-radius: 2px;
  outline: none !important;
  margin-top: 16px;
  padding: 10px 30px;
  height: auto;
  max-width: 200px;
  color: #fff !important;
  border: 1px solid rgb(14, 118, 214) !important;
}

.subbanner-text {
  text-transform: uppercase;
  color: #8d8d8d;
  font-size: 12px;
  width: 150px;
  align-self: center;
}

.bonus-receive {
  font-size: 101px;
  font-weight: 700;
  text-align: center;
  color: rgb(61 61 61 / 93%);
}

.card-iux-landing-page {
  background: #242424;
  /* box-shadow: 0px 0px 12px 0px #a7d3ff80; */
  border-radius: 20px;
  padding: 46px;
  height: 100%;
}
.card-iux-about-page {
  background: #242424;
  /* box-shadow: 0px 0px 12px 0px #a7d3ff80; */
  border-radius: 20px;
  padding: 40px;
  height: auto;
}

.bonus-receive-50 {
  font-size: 101px;
  font-weight: 700;
  text-align: center;
  color: #0162ac;
}
.border-receive-bonus {
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 350px;
  border-radius: 10px;
  /* height: 500px; */
}

.p-24 {
  padding: 20px;
}

.p-12 {
  padding: 12px !important;
}

.a-policy {
  text-align: center;
  margin-top: 12px !important;
}
.p-bonus {
  font-size: 24px;
  text-align: center;
  font-weight: 700;
  margin-bottom: 4px !important;
}
.mb-58 {
  margin-top: 46px !important;
}

.margin-box-content-bonus {
  margin: 12px 3rem;
}

.step-landing {
  max-width: 200px;
  margin: 2%;
}

.d-banneriux {
  display: inline-table;
}

.d-visible {
  display: flex !important;
}

.auto-center-mobile-byleft {
  text-align: left;
}

.step-iux {
  width: 48px;
  height: 48px;
  margin: 5px;
  border: 1px solid gray;
  font-size: 16px;
  border-radius: 50%;
  line-height: 48px;
  background-clip: background-clip;
  text-align: center;
  float: left;
}

.text-bonus {
  background: #dcbe59;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 156px;
  font-weight: bold;
  font-style: italic;
  line-height: 1;
  text-transform: uppercase;
  padding-right: 16px;
}

.text-sub-bonus {
  font-weight: bold;
  font-family: sans-serif;
  line-height: 1;
  font-size: 200px;
  text-transform: uppercase;
  color: #dcbe59 !important;
}

.step-iuximg {
  width: 200px;
  height: 200px;
}

.display-responsive {
  display: block;
  justify-content: center;
}

.iuxicon-panal {
  width: 100%;
  height: 70px;
}

.iux-panal {
  margin: 0rem !important;
}

.contact-max-width-content {
  max-width: 100%;
}

.width-component-responsive {
  width: 670px;
}

.styleMainBox {
  border-radius: 6px;
  width: 100%;
}

.title-font-mobile {
  white-space: pre-line;
  font-size: 15px;
}

.paddig-responsive-landingpage {
  padding: 4rem 12px;
}

.text-initial {
  text-align: initial;
}

.sub-menu-page {
  position: relative;
  top: -49px;
}
.sub-menu-page-user {
  position: relative;
  top: -20px;
}
.scroll-menu-hide-user::-webkit-scrollbar {
  /* display: none; */
  background-color: #fff;
  border-radius: 100px;
  height:  8px;
}
.scroll-menu-hide-user::-webkit-scrollbar-thumb {
  background-color: #1cb894;
  border-radius: 100px;
  
}
@media screen and (max-width: 768px) {
  .scroll-menu-hide-user::-webkit-scrollbar {
    display: none;
   
  }
}
.scroll-menu-hide::-webkit-scrollbar {
  /* display: none; */
  background-color: #000;
  border-radius: 100px;
  height:  8px;
}
.scroll-menu-hide::-webkit-scrollbar-thumb {
  background-color: #1cb894;
  border-radius: 100px;
  
}
@media screen and (max-width: 768px) {
  .scroll-menu-hide::-webkit-scrollbar {
    display: none;
   
  }
}

.width-section-lg-md-sm {
  width: auto;
}

.bg-lotback {
  background-color: transparent !important ;
}


.hot-banner {
  width: 0;
  height: 0;
  border-top: 40px solid #009eff;
  border-right: 40px solid transparent;
  position: absolute;
  top: 0;
  left: 0;
}

.border-md-right {
  border-right: 1px solid #80808054;
}
@media screen and (min-width: 2000px) {
  .rectangle-card-login {
    padding: 40px 4%;
    max-width: 700px;
    height: auto;
    /* border: 1px solid #e7e7e7; */
  }
}
@media only screen and (max-width: 1440px) {
  .w-responsive-img-mt-5-promo {
    width: 450px;
  }

  .width-section-lg-md-sm {
    /* width: 60%; */
    width: auto;
  }

  .div-transform {
    /* position: absolute; */
    background: #242424;
    z-index: 1;
    /* right: -60px; */
    margin-top: 10px;
    width: 268px;
    /* transform: skew(-6deg); */
    box-shadow: 0 0 19px 10px #2ab8ff14;
    height: auto;
    border-radius: 20px;
  }

  .main-curs {
    height: 824px;
  }

  .iux-bright {
    text-align: left !important;
  }

  .banner-section {
    background-color: rgba(10, 10, 10, 0.55);
    padding: 16px;
    text-align: center;
    position: absolute;
    top: 84.9%;
  }

  .card-banner {
    width: auto;
    margin: 8px !important;
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 1024px) {
  .card-iux-about-page {
    background: #242424;
    /* box-shadow: 0px 0px 12px 0px #a7d3ff80; */
    border-radius: 20px;
    padding: 40px;
    height: 100%;
  }
  .w-responsive-img-mt-5-promo {
    width: 350px;
  }
  .w-responsive-img-mt-5 {
    width: 400px !important;
  }
  .h-bg-cover-mt-5 {
    height: 280px;
  }
  .img-mt5-landing-page {
    position: absolute;
    right: 0%;
    margin-top: -12%;
  }

  .w-responsive-img-mt-5 {
    width: 450px;
  }

  .f-56 {
    font-size: 40px;
  }

  .f-40 {
    font-size: 30px;
  }

  .f-32 {
    font-size: 18px;
  }

  .styleMainBox {
    width: 83%;
    margin: 2rem auto;
  }

  .width-section-lg-md-sm {
    width: 100%;
  }

  .div-transform {
    display: none;
  }
  .title-font-mobile {
    text-align: left;
  }

  .banner-dowload-ios-an {
    display: flex;
    position: absolute;
    margin-top: 14px !important;
    margin-left: 16px !important;
    width: 11% !important;
    justify-content: space-between;
  }

  .contact-max-width-content {
    max-width: 100%;
  }
  .ant-menu-item > .text-white {
    color: black !important;
  }

  .p-7 {
    padding: 2rem !important;
  }

  .ant-menu-item > a {
    color: black !important;
  }

  .ant-row {
    /* text-align: center; */
    display: block;
  }

  .ant-drawer-body > ul > li > span > a,
  .ant-drawer-body > ul > li > div {
    color: black !important;
  }

  .ant-radio-wrapper {
    white-space: normal !important;
  }

  .requirement-title {
    float: left;
    max-width: 600px;
    margin-left: 0;
  }

  .row {
    margin: auto;
  }

  .ant-card-bordered {
    right: auto !important;
  }

  .meta-trader {
    display: block !important;
  }

  .bank_support {
    width: 234px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    text-align: center;
    word-wrap: break-word;
    width: 100% !important;
    margin: 0px !important;
  }

  .deposit-button {
    padding: 0% 0%;
    margin-top: 16px;
    text-align: center;
  }

  .android-button {
    padding: 0% 0%;
    margin-top: 16px;
    text-align: center;
  }

  .iphone-ipad-button {
    padding: 0% 0%;
    margin-top: 16px;
    text-align: center;
  }

  .partner-button {
    padding: 0% 0%;
    margin-top: 16px;
    text-align: center;
  }
}
@media only screen and (max-device-width: 1400px) {

  .carousel-caption{
    left: 5%;
    right: 5%;
  }
}
@media only screen and (max-device-width: 1200px) {

  
  .main-curs {
    height: 990px;
  }
}

@media only screen and (max-device-width: 1024px) {
  .banner-titleheader {
    white-space: inherit;
  }

  .carousel-caption {
    top: 5% !important;
  }

  .btn-free30 {
    /* width: 98% !important; */
  }
  .btn-standardx {
    width: 98% !important;
  }

  .f-20 {
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    font-family: sans-serif;
  }

  .main-curs {
    height: 80%;
  }

  .mobile-responsive {
    height: 1100px !important;
  }

  .iux-platform-img {
    height: auto;
    width: 425px;
  }

  .banner-section {
    background-color: rgba(10, 10, 10, 0.55);
    padding: 16px;
    text-align: center;
    position: absolute;
    top: 86%;
  }

  .mb-40 {
    margin-bottom: 44px;
  }
  .mt-40 {
    margin-top: 40px;
  }

  .justify-center {
    justify-content: center;
  }
  .subbanner-bottom {
    background-color: rgba(0, 0, 0, 0.46);
    padding: 16px;
    text-align: center;
    position: absolute;
    top: 81%;
    width: 100%;
  }

  .card-banner {
    width: auto !important;
    margin: 8px !important;
    display: flex;
    justify-content: center;
  }

  .landing-header {
    padding: 2rem 0px 0px 0px;
  }

  .dflex {
    display: flex !important;
    justify-content: center !important;
  }
  .max-wheader {
    max-width: 100%;
  }

  .d-banneriux {
    display: -webkit-inline-box;
  }
  .card-banner {
    width: 100%;
    margin: 8px !important;
    display: flex;
    justify-content: center;
  }

  .bonustext-banner {
    font-size: 15px;
    color: #fff;
    padding: 8px;
    font-weight: bold;
    text-align: center;
  }

  .bunusvalue-banner {
    font-size: 15px;
    padding: 8px;
    color: #fff;
    font-weight: bold;
  }
  .image-caption-banner {
    width: 756px !important;
    height: 210px !important;
    object-fit: contain;
  }
  .subbanner-text {
    font-size: 0.6rem;
    width: 150px;
    align-self: center;
  }
  .h1-responsive24 {
    font-size: 16px;
    font-weight: 700;
    font-family: sans-serif;
  }
  .text24-center {
    text-align: center;
  }
  .step-landing {
    max-width: 200px;
    margin: 0px 100px !important;
  }
}

.space-card-responsive {
  width: 100%;
  display: inline-table;
  border-radius: 12px;
  text-align: center;
  background: #242424;
}

.about-card-responsive {
  width: 100%;
  display: inline-table;
  border-radius: 12px;
  text-align: center;
  /* background: #000; */
  /* padding: 25px; */
  /* border: 1px solid #009eff99; */
  height: 100%;
}
.about-card-responsive-new {
  width: 100%;
  display: inline-table;
  border-radius: 12px;
  text-align: center;
  background: #242424;
  padding: 40px;
  /* border: 1px solid #009eff99; */
  height: 100%;
}

.platform-description-p {
  float: left;
  margin-left: 15%;
  margin-right: 38%;
}

.f-lg-30 {
  font-size: 30px;
}

.w-respon-button {
  width: 50%;
  margin: auto;
}

.ml-mr-sm-auto {
  margin-left: -15px;
  margin-right: -15px;
}
.word-break-all {
  word-break: break-all;
}

.d-grid {
  display: grid;
}

.b-b-gray {
  border-bottom: 1px solid #80808063;
}

@media screen and (max-width: 767px) {
  .ml-mr-sm-auto {
    margin: auto;
  }
  .width-modal-responsive {
    width: 100% !important;
  }
  .btn-success-outline {
    width: 100%;
  }
  .w-responsive-img-mt-5-promo {
    width: 100%;
  }
  .width-lg-50-sm-100 {
    width: 100%;
  }
  .d-flex-wrap-auto-iux {
    display: block !important;
  }
  .f-md-24 {
    font-size: 24px;
  }

  .text-bonus {
    background: #dcbe59;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 142px;
    font-weight: bold;
    font-style: italic;
    line-height: 1;
    text-transform: uppercase;
    padding-right: 16px;
  }

  .text-sub-bonus {
    font-weight: bold;
    font-family: sans-serif;
    line-height: 1;
    font-size: 164px;
    text-transform: uppercase;
    color: #1cb880 !important;
  }

  .banner-dowload-ios-an {
    display: flex;
    position: absolute;
    margin-top: 6px !important;
    margin-left: 12px !important;
    width: 10% !important;
    justify-content: space-between;
  }
  .styleMainBox {
    width: 100%;
    margin: 2rem auto;
  }

  .box-market-landingpage {
    width: 100%;
  }

  .btn-free30 {
    /* width: 90% !important; */
  }

  .btn-standardx {
    width: 90% !important;
  }

  .mobile-responsive {
    height: 1460px !important;
  }

  .main-curs {
    height: 1460px;
  }

  .bank_support {
    width: 138px;
  }

  .margin-card-img-deposit {
    margin: 0;
    width: auto;
    display: inline-block;
  }

  .background-section-platform {
    background: none;
  }
  .st .style-platform-all {
    font-size: 22px;
    font-weight: 700;
    font-family: sans-serif;
    margin-top: 25px !important;
  }

  .font-responsive-subtitle {
    font-size: 20px !important;
  }

  .margin-description-platform {
    margin: 4rem 0 0;
  }

  .platform-description-p {
    float: left;
    margin-left: 0;
    margin-right: 0;
  }

  .pl-pr-sm {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .space-card-responsive {
    width: 100%;
  }

  .text-align-left-sm {
    text-align: left !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }
  .responsive-item-box-mobile {
    padding: 34px !important;
    text-align: left !important;
  }
  .img-platform-all-responsive {
    width: 100%;
    height: 300px;
    object-fit: scale-down;
  }

  .auto-center-mobile-byleft {
    text-align: center;
  }
  .d-visible {
    display: none !important;
  }

  .text-leftresponsive {
    text-align: center !important;
  }
  .text-cneterresponsive-content {
    font-size: 14px;
  }
  .text-leftresponsive-content {
    font-size: 14px;
  }

  .text-righttresponsive {
    text-align: center !important;
  }

  .banner-section {
    background-color: rgba(10, 10, 10, 0.55);
    padding: 16px;
    text-align: center;
    position: absolute;
    top: 86.9%;
  }

  .border-right {
    border-right: 0px solid !important;
  }

  .landing-header {
    padding: 1rem 0px 0px 0px;
  }

  .mb-40 {
    margin-bottom: 30px;
  }

  .subbanner-bottom {
    background-color: rgba(0, 0, 0, 0.46);
    padding: 16px;
    text-align: center;
    position: absolute;
    top: 78.1%;
    width: 100%;
  }

  .dflex {
    display: flex !important;
    justify-content: center !important;
  }

  .card-banner {
    width: 100% !important;
    margin: 8px !important;
    display: flex;
    justify-content: center;
  }

  .mobile-responsive {
    height: 700px;
  }

  .f-20 {
    font-size: 18px;
    font-weight: 700;
    text-align: left;
  }

  .btn-free30 {
    /* margin-top: 30px !important; */
    padding: 14px 20px !important;
    color: aliceblue !important;
    border-radius: 2px !important;
    border: 0px !important;
    text-transform: uppercase;
  }

  .btn-standardx {
    margin-top: 30px !important;
    padding: 14px 28px !important;
    color: aliceblue !important;
    border-radius: 2px !important;
    border: 0px !important;
    text-transform: uppercase;
    box-shadow: 1px 3px 5px 0px #00000036;
  }

  .banner-titleheader {
    font-size: 36px;
    white-space: break-spaces;
    text-align: left;
  }

  .step-landing {
    max-width: 100%;
    margin: auto !important;
  }
  /* .content {
		position: fixed;
		bottom: 0;
		background: rgba(0, 0, 0, 0);
		color: #f1f1f1;
		width: 100%;
		padding: 0px;
		height: 100%;
	} */
  .text-in-cover-video {
    /* display: none; */
    padding-top: 0%;
    padding-bottom: 10%;
    /* width: 100%; */
  }

  .d-res-none {
    display: none;
  }
  .p-t-70 {
    padding-top: 16px !important;
  }
  .p-t-40 {
    padding-top: 16px !important;
  }
  .p-t-0 {
    padding-top: 16px !important;
  }

  .border-receive-bonus {
    border: 0px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    /* height: 534px; */
  }
  .promotion-text {
    font-weight: 700;
    font-size: 42px;
    text-align: center;
    color: rgb(161, 161, 161);
  }
  .promotion-border {
    padding: 40px 32px;
    border-left: 0px solid #0162ac;
  }
  .p-7 {
    padding: 1rem !important;
  }
  .p-24 {
    padding: 0px;
  }
  .d-none-addvisa {
    display: none !important;
  }
  .creditcard-save {
    display: inline-block;
    border: 1px solid rgba(128, 128, 128, 0.226);
    padding: 12px 16px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.18);
    margin-bottom: 26px;
  }
  .text-in-cover {
    padding: 4% !important;
  }
  .text-in-cover-text-video {
    padding-left: 4%;
  }
  .f-right {
    float: none;
  }
  .iux-p5 {
    padding: 5rem 0;
  }

  .iux-platform-img {
    width: auto;
    max-width: 100%;
    margin-top: 70px;
  }

  .f-16 {
    font-size: 16px;
  }

  .btn-inline {
    padding: 0px 0px 8px;
  }

  .why-trade-btn {
    z-index: 2;
    margin-bottom: 0;
    border-radius: 16px;
  }

  .plateform-btn {
    position: unset;
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 16px;
    display: inline;
  }

  .iux-block {
    border: 0px solid rgb(223, 230, 233);
    max-width: 100%;
    border-radius: 9px;
    padding: 50px 20px 60px;
  }

  .border-left {
    border-left: 0px solid #dee2e6 !important;
  }

  .ant-radio-wrapper {
    padding: 8px;
  }

  .resolution-display {
    padding: 90pt 0px;
  }

  .rectangle-card {
    margin: 30% 0%;
    padding: 30px 24px;
    border-radius: 3px;
    background-color: #242424;
    max-width: 400px;
    width: 100%;
  }

  .rectangle-card-login {
    padding: 36px;
    border-radius: 3px;
    /* background-color: #ffffff; */
    max-width: 400px;
    width: 100%;
    /* border: 0px solid #e7e7e7; */
  }

  .rectangle-card-reset {
    padding: 80px 2%;
    border-radius: 5px;
    background-color: #ffffff;
    max-width: 600px;
    /* max-height: 900px; */
    opacity: 1;
    display: inline-block;
    width: 100%;
    margin-top: 160px;
  }

  .mt-responsive {
    margin-left: 45px;
  }

  .cardStyle {
    width: auto;
  }

  .btn-mt4-size {
    margin-top: 16;
    padding: 0% 10% 0% 4%;
  }

  .partner-btn-responsive {
    margin-left: 60px;
  }

  .trading-program {
    margin: 16px !important;
    width: auto;
  }

  .trading-program-content {
    background: rgb(255, 255, 255);
    padding: 0px;
    margin-top: 24px;
    border-radius: 5px;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px 0px; */
  }
}

@media screen and (max-width: 480px) {
  .iux-contactsocial {
    margin-right: 16px;
  }
  .width-lg-50-sm-100 {
    width: 100%;
  }
  .mobile-responsive {
    height: 1960px !important;
  }

  .img-mt5-landing-page {
    position: relative;
    right: auto;
    margin-top: 0;
  }

  .h-bg-cover-mt-5 {
    height: 100%;
  }

  .w-responsive-img-mt-5 {
    width: 100% !important;
  }

  .w-btn-success-custom {
    width: 100%;
  }

  .p-50-sm-20 {
    padding: 20px;
  }

  .f-42 {
    font-size: 30px;
  }

  .p-70-sm-20 {
    padding: 40px 20px 20px 20px;
  }
  .w-responsive-img-mt-5-promo {
    width: 100%;
  }

  .main-curs {
    height: 1380px !important;
  }

  .height-100vh {
    height: 100%;
  }
}
@media screen and (max-width: 425px) {
  .border-md-right {
    border-right: none !important;
  }
  .box-banner {
    height: 130px;
  }
  .pt-100-mobile {
    padding-top: 100px;
  }
  .d-flex-wrap-auto-iux {
    display: block !important;
  }
  .card-partner-transform {
    height: 100%;
  }
  .height-100vh {
    height: 100%;
  }

  .iux-block {
    border: 0px solid rgb(223, 230, 233);
    max-width: 100%;
    border-radius: 9px;
    padding: 20px 20px 20px;
  }
  .w-md-50-w-sm-100 {
    width: 100%;
  }

  .cover-responsive {
    height: 60vh;
  }

  .paddig-responsive-landingpage {
    padding: 1rem 12px;
  }

  .margin-box-content-bonus {
    margin: 12px 0.2rem;
  }

  .banner-dowload-ios-an {
    display: flex;
    position: absolute;
    margin-top: 33px !important;
    margin-left: 26px !important;
    width: 41.4% !important;
    justify-content: space-between;
  }
  .h1-responsive24 {
    font-size: 34px;
    font-weight: 700;
    font-family: sans-serif;
  }

  .btn-free30 {
    /* width: 90% !important; */
  }

  .btn-standardx {
    width: 90% !important;
  }

  .styleMainBox {
    margin: 1rem auto;
  }

  .box-market-landingpage {
    width: 100%;
  }

  .width-component-responsive {
    width: 100%;
  }

  .pb-pt-textCover {
    padding-top: 40%;
    padding-bottom: 40%;
  }
  .col-md-landingPage {
    min-width: -webkit-fill-available;
    margin-right: 20px;
    margin-bottom: 16px;
  }

  .lading-icon-socail {
    min-width: 32px;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
  }

  .landing-float-RL {
    float: left;
  }

  .landingPage-flex-block {
    display: block !important;
    text-align: left;
  }

  .landingPage-footerSelection {
    /* padding: 5% 7% 0px; */
    background-color: rgb(34, 34, 34);
  }

  .p-0-important {
    padding-left: 0px !important;
  }

  .main-resonsive-banner {
    /* min-width: 50% !important;
    max-width: 50% !important; */
  }
  .img-bottomResonsive {
    /* width: auto; */
    height: 400px;
  }

  .menuBar {
    padding: 12px 25px 0px 25px;
    background-color: #00000000;
    position: absolute;
    display: flex;
    justify-content: space-between;
    z-index: 99;
    width: 100%;
    overflow: hidden;
    /* border-bottom: 1px solid rgba(207, 207, 207, 0.267); */
  }

  .dflex {
    display: block !important;
    justify-content: center !important;
  }

  .subbanner-bottom {
    background-color: rgba(0, 0, 0, 0.46);
    padding: 16px;
    text-align: center;
    position: absolute;
    /* z-index: 100000000000; */
    top: 66%;
    width: 100%;
  }

  .mobile-responsive {
    height: 100% !important;
  }

  .mb-40 {
    margin-bottom: 20px;
  }

  .banner-titleheader {
    font-size: 40px;
    font-weight: bold;
    font-family: sans-serif;
    text-align: left;
    white-space: break-spaces;
    line-height: 1.2;
  }

  .title-font-mobile {
    font-size: 16px;
    padding: 12px;
    text-align: left;
    white-space: break-space;
  }

  .landing-header {
    padding: 0;
  }

  .btn-free30 {
    margin-top: 0px !important;
    padding: 14px 20px !important;
    color: aliceblue !important;
    border-radius: 2px !important;
    border: 0px !important;
    text-transform: uppercase;
  }

  .btn-standardx {
    margin-top: 16px !important;
    padding: 14px 28px !important;
    color: aliceblue !important;
    border-radius: 2px !important;
    border: 0px !important;
    text-transform: uppercase;
    box-shadow: 1px 3px 5px 0px #00000036;
  }

  .card-banner {
    width: auto !important;
    margin: 8px !important;
    display: flex;
    justify-content: center;
  }

  .step-iux {
    width: 30px;
    height: 30px;
    margin: 0px 6px 0px 0px;
    font-size: 16px;
    border: 1px solid gray;
    border-radius: 50%;
    line-height: 28px;
    text-align: center;
    float: left;
  }

  .f-res16 {
    font-size: 16px;
  }

  .step-iuximg {
    width: 150px;
    height: 150px;
  }

  .display-responsive {
    display: flex;
    justify-content: center;
  }

  .iuxicon-panal {
    width: 100%;
    height: 70px;
  }

  .iux-panal {
    margin: 1rem !important;
  }

  .text-iconpanal {
    font-size: 18px;
  }

  .iux-bleft {
    text-align: center !important;
  }

  .iux-bright {
    text-align: center !important;
  }
  .main-curs {
    height: 1800px;
  }

  .banner-section {
    background-color: rgba(10, 10, 10, 0.55);
    padding: 16px;
    text-align: center;
    position: absolute;
    top: 84%;
  }

  .promotion-border {
    padding: 0px 32px;
    border-left: 0px solid #0162ac;
  }

  .subbanner-text {
    font-size: 1rem;
    width: 150px;
    align-self: center;
  }
}

@media (max-width: 375px) {
  .registerlogo {
    width: auto;
    height: 60px;
    margin-left: 0px;
    border-radius: 8px;
  }
  .ant-message {
    top: 450px;
  }
  .banner-dowload-ios-an {
    display: flex;
    position: absolute;
    margin-top: 26px !important;
    margin-left: 22px !important;
    width: 40.4% !important;
    justify-content: space-between;
  }
}

.spinning-loader {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid rgba(29, 161, 242, 0.2);
  border-left-color: rgb(29, 161, 242);
  background: transparent;
  animation: rotate-s-loader 1s linear infinite;
}

@keyframes rotate-s-loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.pl-3-5 {
  padding-left: 20px;
}
.pr-3-5 {
  padding-right: 20px;
}
.px-3-5 {
  padding-right: 20px;
  padding-left: 20px;
}


.fs-56-lot {
  font-size: 56px;
}
.fs-footer-widget {
  font-size: 14px;
}
@media (max-width: 950px) {
  .fs-footer-widget {
    font-size: 12px;
  }
}
@media (max-width: 480px) {
  .fs-56-lot {
    font-size: 48px;
  }
.fs-footer-widget {
  font-size: 8px;
}

}

.cursor-pointer {
  cursor: pointer;
}

.ant-divider-horizontal.ant-divider-with-text-center::before {
  border-top: 1px solid #3d3d3d !important;
}
.ant-divider-horizontal.ant-divider-with-text-center::after {
  border-top: 1px solid #3d3d3d !important;
}
.bg-primary-new-iux {
  background-color: #1cb894;
}
.bg-gray-new-iux {
  background-color: #242424;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #fff !important;
  font-size: 12px;
}
.user .ant-collapse-content-box {
  padding-left: 0px !important;
}
.user .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #000 !important;
  font-size: 12px;
  padding: 12px 32px 0px 0px;
}
.user .contactus .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #000 !important;
  font-size: 12px;
  padding: 12px 16px;
  padding-left: 40px;
}

.ant-select-selection {
  background-color: green;
}
.ant-input-password-icon {
  color: #8d8d8d !important;
}
.ant-form-item-label > label {
  color: #8d8d8d !important;
}
.ant-form-item-label {
  display: block;
  white-space: initial !important;
}

.ant-form-item-has-error .ant-input {
  background-color: unset !important;
}
.user .ant-picker {
  height: 48px;
  border-radius: 8px;
}
.user .ant-input {
  text-overflow: ellipsis;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  color: rgba(0, 0, 0, 0.65);
  height: 48px;
  border-radius: 8px;
}
.user-change .ant-input {
  text-overflow: ellipsis;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  color: rgba(0, 0, 0, 0.65);
  border-radius: 8px;
}
.user .ant-input-group-addon {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
}
.verify .ant-input-group-addon {
  border: 1px solid #d9d9d9;
}
.user .ant-input:placeholder-shown {
  text-overflow: ellipsis;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  color: rgba(0, 0, 0, 0.65);
  height: 48px;
  border-radius: 8px;
}
.user .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #3c3c3c;
  border-radius: 8px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  padding: 0 11px;
}
.user .ant-select-selector {
  text-overflow: ellipsis;
  background-color: #fff !important;
  border: 1px solid #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.65);
  height: 48px !important;
  padding: 8px !important;
  border-radius: 8px;
  align-items: center;
}
.user .user-tranfer .ant-select-selector {
  text-overflow: ellipsis;
  background-color: #fff !important;
  border: 1px solid #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.65);
  height: 48px !important;
  padding: 8px !important;
  border-radius: 8px;
  align-items: "unset";
}

.ant-alert-warning .ant-alert-description {
  color: #ffa800;
  font-size: 10px;
}
.ant-alert-with-description {
  padding: "8px" !important;
}
.ant-alert-with-description .ant-alert-icon {
  top: 40% !important;
  left: 4% !important;
}
.user .ant-tabs-nav-wrap {
  padding: 20px;
}
.text-wrap-desktop {
  white-space: nowrap;
}
@media (max-width: 480px) {
  .text-wrap-desktop {
    white-space: wrap;
  }
}
.font-number-withdraw {
  min-width: unset;
  max-width: unset;
  font-size: 64px;
}
@media (max-width: 1024px) {
  .font-number-withdraw {
    min-width: 92px;
    max-width: 100px;
  }
}
@media (max-width: 450px) {
  .font-number-withdraw {
    min-width: 92px;
    max-width: 100px;
  }
}
.img-location-aboutus {
  width: 100%;
}
@media (max-width: 450px) {
  .img-location-aboutus {
    width: 100%;
  }
}
.alert-verify {
  border-radius: 8;
  color: #ffa800;
  border: unset;
  padding: 8px 8px 8px 64px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}
@media (max-width: 1024px) {
  .alert-verify {
    width: 100%;
  }
}
.ant-select-arrow {
  color: #a0a0a0 !important;
}
.footer-user {
  margin-left: 125px;
}
@media (max-width: 1024px) {
  .footer-user {
    margin-left: 0px;
  }
}
.border-round {
  border-radius: 20px;
}
.border-round-16 {
  border-radius: 16px;
}
.container-landing-title {
  width: 690px;
}
@media (max-width: 1024px) {
  .container-landing-title {
    width: 100%;
  }
}
.border-12 {
  border-radius: 12px !important;
}
.border-input {
  border-radius: 6px !important;
  border: 1px solid #3c3c3c;
}
.logo-w {
  width: 100px;
}
.logo-s-landing {
  height: 72px;
  width: 100%;
}
.text-title-cover {
  font-size: 56;
}
@media (max-width: 1200px){
  .container {
    max-width: 1000px;
}
}
@media (max-width: 1200px) {
  .logo-s-landing {
    height: 50px;
  }
}
@media (max-width: 600px) {
  .logo-w {
    width: 100%;
  }
  .logo-s-landing {
    height: 40px;
  }
  .text-title-cover {
    font-size: 48;
  }
}
.ant-select-selection-item {
  height: 100%;
}
@media (min-width: 1500px) {
  .main-curs {
    height: 830px;
  }
}
.text-red {
  color: #cc0000;
}
.ant-empty-description {
  text-align: center;
}
.mt-40 {
  margin-top: 40px;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-80 {
  margin-top: 80px;
}
.mr-40 {
  margin-right: 40px;
}
/* .bt-table {
  border-top: 1px solid #8d8d8d;
}
.bb-table {
  border-bottom: 1px solid #8d8d8d;
}
.bl-table {
  border-left: 1px solid #8d8d8d;
}
.br-table {
  border-right: 1px solid #8d8d8d;
} */

/* .table-curved {
 
  border: solid #8d8d8d 1px;
  border-radius: 25px;
  overflow: hidden;
}
.table-curved tr:last-child td {
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
} */
table td,
table th {
  padding: 0.5rem;
  text-align: left;
  vertical-align: top;
}
/*
 * Add the border, set border spacing etc
 * The left width is 0 so cell border can be applied without doubling up.
 */

.table-bordered-iux {
  border: 1px solid silver;
  border-left-width: 0;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 1rem;
}
/*
 * Add the border to table cell/header
 */
.table-bordered-iux th {
  border-top: 0px solid silver;
  border-left: 0px solid silver;
}
.table-bordered-iux th:first-child {
  border-top: 1px solid silver;
  border-left: 1px solid silver;
}
.table-bordered-iux td {
  border-top: 1px solid silver;
  border-left: 1px solid silver;
}
/*
 * Remove the top border from the first header/cell
 */

.table-bordered-iux tbody:first-child tr:first-child td,
.table-bordered-iux thead:first-child tr:first-child th {
  border-top-width: 0;
}
/*
 * Set the border radius for the first header/cell
 */

.table-bordered-iux thead:first-child tr:first-child td:first-child,
.table-bordered-iux thead:first-child tr:first-child th:first-child {
  border-top-left-radius: 1rem;
}
/*
 * Set the border radius for the last header/cell
 */

.table-bordered-iux tbody:last-child tr:last-child td:first-child,
.table-bordered-iux tbody:last-child tr:last-child th:first-child {
  border-bottom-left-radius: 1rem;
}
.ant-modal-footer {
  padding: 0px 16px 16px 16px !important;
  border-top: unset !important;
}
.ant-modal-body {
  padding: 16px 16px 16px 16px !important;
}
.user.ant-upload.ant-upload-drag {
  border: 0;
  border-radius: 8px;
}
.header-menu-icons:hover svg .fill-primary {
  fill: #1cb894;
}
.header-menu-icons-dropdown{
  color: #1e1e1e;
}
.header-menu-icons-dropdown:hover{
  color:#1cb894;
}
.header-menu-icons-dropdown:hover svg .fill-primary {
  stroke: #1cb894;
}

.bookbank-container {
  width: 320px;
  border-radius: 12px;
}
@media (max-width: 450px) {
  .bookbank-container {
    width: 100%;
    border-radius: 12px;
  }
}
.text-overflow-ellipsis{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media (max-width: 550px) {
  /* .ant-form-item-label > label{
    height: auto;
  } */
.upload-verify .ant-form-item-label > label {
  height: 50px;
  font-size: 10px;
}

.ant-upload-list-item-info .ant-upload-text-icon .anticon{
  left:0
}
}
.ant-form-item-has-error .ant-input{
  border-color:#ff4d4f !important
}
.upload-verify .ant-form-item-label > label::after{
  content:unset
}
.box-bonus{
  border: 1px solid #EEEEEE;
border-radius: 10px;
padding: 20px;
}
.social-button-container {
  position: fixed;
  top:40%;
  height:168px ;
  background: rgba(153, 153, 153, 0.2);
  z-index: 99999;
  right: 0;
  border-radius: 20px 0px 0px 20px;
  width: 50px;
}
@media (max-width: 450px) {
 .social-button-container {
  position: fixed;
  top:40%;
  height:168px ;
  background: rgba(153, 153, 153, 0.2);
  z-index: 99999;
  right: 0;
  border-radius: 20px 0px 0px 20px;
  width: 30px;
}
}
.social-button-container-dashboard {
  position: fixed;
  top:40%;
  height:168px ;
  background: #D9D9D9;
  z-index: 99999;
  right: 0;
  border-radius: 20px 0px 0px 20px;
  width: 50px;
}
@media (max-width: 450px) {
 .social-button-container-dashboard {
  position: fixed;
  top:40%;
  height:168px ;
  background: #D9D9D9;
  z-index: 99999;
  right: 0;
  border-radius: 20px 0px 0px 20px;
  width: 30px;
}
}

.social-button-line{


  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.social-button-line:hover g rect{
  fill: #53B635;
}
.social-button-telegram{


  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.social-button-telegram:hover g rect{
  fill: #52A3E1;
}
.social-button-facebook{

  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.social-button-facebook:hover g rect{
  fill: #3982F7;}
.box-bonus:hover{
  border: 1px solid #1cb894;
border-radius: 10px;
padding: 20px;
}
.box-bonus.active{
  border: 1px solid #1cb894;
border-radius: 10px;
padding: 20px;
}
.box-bonus-disabled{
  /* background-color:#1cb894 ; */
  border: 1px solid #EEEEEE;
  border-radius: 10px;
  padding: 20px;
 }
.bage-condition-notyet{
  width: fit-content;
  color: #E3673F;
  padding: 5px 8px;
  background: rgba(227, 103, 63, 0.2);
  border-radius: 14px;
}
.bage-condition-processing{
  width: fit-content;
  color: #EDC551;
  padding: 5px 8px;
  background: rgba(237, 197, 81, 0.2);;
  border-radius: 14px;
}
.bage-condition-complete{
  width: fit-content;
  color: #78F26E;
  padding: 5px 8px;
  background: rgba(120, 242, 110, 0.2);
  border-radius: 14px;
}
.footer-contact-container{
  height: 0;
  /* max-height: 710px; */
  right: 74px;
  bottom: 0px;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;

}
.footer-contact-container-show{
  height: 295.2px;
  /* max-height: 710px; */
  right: 74px;
  bottom: 0px;
  transition: all 0.3s ease-in-out;
}
.widget--collapsed .messengers{
  height: 0;
  opacity: 0;
  visibility: hidden;
}
.chat24-container{
  position: fixed;
  right: 5vh;
  bottom: 5vh;
  background-color: red;
  /* width: 0px; */
}
blinkid-in-browser {
  max-width: 400px;
  width: 400px;
  height: 400px;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled){
  background-color: #D9D9D980 !important
}
.ant-select-item-option div{
  align-items: center;
}