.App-payment {
  padding: 12px;
  display: flex;
}

.form-creditcard {
  margin: 0;
  max-width: 400px;
}
.form-actions {
  margin-top: 15px;
}

.App-cards {
  margin: 0 auto;
  max-width: 1280px;
  text-align: center;
}

.App-cards-list {
  display: flex;
  margin-top: 30px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.App-cards-list > * {
  transform: scale(0.8);
  margin-bottom: 30px !important;
}

.App-highlight {
  font-family: serif;
  margin: 15px auto 0;
  max-width: 300px;
}

.App-highlight > div {
  padding-left: 40px;
}

.App-badges {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.App-badges a {
  display: block;
}

.App-badges a + a {
  margin-top: 18px;
}

.github__btn {
  align-items: center;
  background-color: #ccc;
  border-radius: 4px;
  color: #444;
  display: flex;
  line-height: 1;
  padding: 8px 12px;
}

.github__btn:hover {
  color: #444;
  text-decoration: none;
}

.github__btn img {
  display: inline-block;
  height: 28px;
  margin-right: 8px;
}

.App-credits {
  background-color: #000;
  color: #fff;
  line-height: 50px;
  text-align: center;
}

.App-credits a {
  color: #fff;
  font-weight: bold;
}

.check-box {
  margin-top: 16px;
}

.card-sizeing {
  padding: 0px 20px 15px 0px;
}

.creditcard-save {
  display: inline-block;
  padding: 0px;
}

.rccs {
  width: 380px !important;
}

.rccs__card {
  width: 380px !important;
  height: 250px !important;
  position: relative;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.rccs__card--front {
  box-shadow: 0 0 4px rgba(100, 100, 100, 0.6) !important;
}

.carousel-control-prev-icon {
  display: none;
}

.carousel-control-next-icon {
  display: none;
}

.border-promotion {
  border: 0px solid #cdcdcd;
  border-radius: 6px;
  display: flex;
}

.checkcolor {
  color: green;
}
@media screen and (min-width: 600px) {
  .App-badges {
    flex-direction: row;
  }

  .App-badges a + a {
    margin-top: 0;
    margin-left: 18px;
  }
}

@media screen and (max-width: 768px) {
  .form-creditcard {
    margin: 30px auto 0;
    max-width: 400px;
  }

  .rccs__card {
    height: 182.873px;
    margin: 0 auto;
    position: relative;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transition: all 0.4s linear;
    transition: all 0.4s linear;
    width: auto !important;
  }

  .rccs {
    margin: 0 auto;
    -webkit-perspective: 1000px;
    perspective: 1000px;
    width: auto !important;
  }

  .App-payment {
    padding: 15px;
    display: flex;
  }
  .check-box {
    margin-top: 16px;
  }
  .creditcard-save {
    display: contents;
    padding: 12px;
  }
  .card-sizeing {
    padding: 0px;
  }

  .rccs__card {
    height: 180px !important;
  }
  .text-center-md {
    text-align: center;
  }
}
