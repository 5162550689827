@import "./variable";
.btn-gradient-bonus {
  border-radius: 6px;
  background: linear-gradient(148deg, #32a796 0%, #3296d0 100%);
  color: $white-color;
  border: none;
  padding: 12px 0;
}
.btn-transparent {
  background: transparent;
  border: 0;
}
.btn-outline-transparent {
  background: transparent;
  border-radius: 10px;
  border: 1px solid $badge-disabled;
  padding: 12px;
}
