@import "./_variable";
.box-setting-content {
  border-radius: 10px;
  background: $bg-gray;

  padding: 0px 16px 0px 20px;
}
.box-detail-setting {
  border-bottom: 1px solid rgba(153, 153, 153, 0.2);
}
.box-otp-setting {
  border-radius: 10px;
  background: $bg-light-gray;
}
.ant-message-notice-content {
  border-radius: 10px !important;
}
