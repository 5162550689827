@import "./variable";
.bg-swap-status {
  background: $bg-light-gray;
  border-radius: 20px;
}
.mw-swap {
  max-width: 522px;
  @media screen and (max-width: 480px) {
    max-width: 100%;
  }
}
