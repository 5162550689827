
@import 'navbar.css';
@import 'spreads.css';
.ant-dropdown-placement-bottomLeft{
    width: 100% !important;
} 
.flip-card {
    background-color: transparent;
    width: 100%;
    height: 200px;
    perspective: 1000px;
   
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    backface-visibility: hidden;
    -moz-backface-visibility: hidden;
  }
  
  .flip-card:focus {
      outline: 0;
  }
  
  /* .flip-card:hover .flip-card-inner,
  .flip-card:focus .flip-card-inner{
    transform: rotateY(180deg);
  } */
  
  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  
  .flip-card-front {
    border-radius: 10px;
    background: rgb(23, 23, 23);
    /* background: linear-gradient(to left, #4364f7, #6fb1fc); */
    color: white;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .flip-card-back {
    border-radius: 10px;
    background: rgb(23, 23, 23);
    /* background: linear-gradient(to right, #4364f7, #6fb1fc); */
    color: white;
    transform: rotateY(180deg);
    z-index: 1;
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */
  }
  @media (max-width: 1024px) {
    .flip-card-inner {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      /* transition: transform 0.6s;
      transform-style: preserve-3d; */
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      backface-visibility: hidden;
      -moz-backface-visibility: hidden;
    }
    .flip-card:focus {
      outline: 0;
  }
  
  .flip-card:hover .flip-card-inner,
  .flip-card:focus .flip-card-inner{
    transform: unset;
  }
  }
  .footer-contact{
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    background: #fff;
    height: 48px;
    /* opacity: 0.9; */
    box-shadow: 0px -9px 40px rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 550px) {
    .footer-contact{
      height: 40px;
    }
  }

  