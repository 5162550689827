.white-space-pre-line {
  white-space: pre-line;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}
.w-xs-auto {
  @media screen and (max-width: 480px) {
    width: auto !important;
  }
}
