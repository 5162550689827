@import "./_variable";
.bage-free-swap {
  &-isfree {
    border-radius: 16px;
    background: rgba(28, 184, 148, 0.1);
    color: $primary-sub-color;
    font-size: 10px;
    font-weight: 400;
    padding: 2px 6px;
  }
  &-nonfree {
    border-radius: 16px;
    background: rgba(227, 103, 63, 0.1);
    color: $error-color;
    font-size: 10px;
    font-weight: 400;
    padding: 2px 6px;
  }
}
