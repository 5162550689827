.styleHeader  {
  background-color: #171717;
  padding: 16px;
  color: rgb(167 167 167);
  font-weight: bold;
  font-family: Inter;  
  border-top-right-radius: 6;
  border-top-left-radius: 6;
}
.bg-spreads-box {
  background-color: #171717;
}
.bg-spreads-box-dashboard {
  background-color: #FFF;
}
