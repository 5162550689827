@import "./variable";
.box-info-bookbank {
  color: $lightgray-color;
  background: $bg-gray;
  padding: 9px 16px;
  align-items: center;
  /* Llight_10px */
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%; /* 12px */
  border-radius: 10px;
}
.box-upload-bookbank {
  border-radius: 10px;
  background: #d2f1ea;
  color: $primary-color;
  padding: 20px 16px;
}
.bookbank .ant-upload-select-text {
  width: 100%;
}
