@import "fund.css";
@import "banner.css";
@import "account.css";

.dropdown-dashboard {
    position: relative;
    display: inline-block;
  }

.dropdown-content-dashboard {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 1;
}
.ant-breadcrumb-separator {
    color:#fff !important
}
.dropdown-dashboard:hover .dropdown-content-dashboard {
    display: block;
}

.d-flex-wrap-auto-iux{
    display: flex;
}

@media screen and (max-width: 767px) {
    .ant-layout-sider{
        position: absolute;
        z-index: 99;
        height: calc(100% - 64px);
        display: none;
    }
}
.economic.ant-table-thead
  > tr
  > th.ant-table-column-has-actions.ant-table-column-has-sorters,
.ant-table-thead > tr > th {
  background-color: #fff;
  color: black;
  border: 0;
}
.economic .ant-table-tbody > tr.ant-table-row > td {
    border: 0;
}
.economic  tr.ant-table-expanded-row > td, tr.ant-table-expanded-row:hover > td {
    background-color: #fff;
}