.custom-chart {
  .scroll-hidden::-webkit-scrollbar {
    display: none;
  }
  .scroll-hidden {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .left-btn-report {
  }
}
