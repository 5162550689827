@import "~antd/dist/antd.css";
@import "./assets/css/index.css";
@import "./assets/scss/_font.scss";

body {
  font-family: "Inter" !important;
}

#app {
  background: white !important;
}

.test {
  color: red;
}

/* .d-flex {
  display: flex !important;
} */

.box-shadow {
  background: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 0 #e6e6e6;
  box-shadow: 0 1px 0 #e6e6e6;
  -webkit-transition: -webkit-box-shadow 0.3s ease-in-out;
  transition: -webkit-box-shadow 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
}

.f-18 {
  font-size: 18px;
}

.effect-button:hover {
  text-decoration: underline;
}
.broder-left {
  border-left: 1px solid gray;
}

.lo-card {
  position: relative;
  padding: 24px;
  height: 110px;
  min-width: 305px;
}

.display-item {
  display: none;
}
.lo-card-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: normal;
  -ms-flex-pack: normal;
  justify-content: normal;
  height: 100%;
  font-size: 14px;
  margin: 0px 5px;
}
.lo-card-img {
  width: 100%;
  height: 100%;
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.lo-svg {
  width: 12px;
  height: 12px;
}

.minwidth-50 {
  min-width: 50px;
}

.auto-hr {
  display: none;
}

.status-silver {
  border-left: 3px solid gray;
}
.status-gold {
  border-left: 3px solid #d09530;
}
.status-platinum {
  border-left: 3px solid #303f49;
}

.lock-item {
  position: absolute;
  color: white !important;
  display: block;
  margin: -10px;
  background-color: grey;
  border-radius: 9px;
  padding: 6px;
  left: 24px;
}

.lock-gold {
  position: absolute;
  color: white !important;
  display: block;
  margin: -10px;
  background-color: #d09530;
  border-radius: 9px;
  padding: 6px;
  left: 24px;
}

.lock-platinum {
  position: absolute;
  color: white !important;
  display: block;
  margin: -10px;
  background-color: #303f49;
  border-radius: 9px;
  padding: 6px;
  left: 24px;
}

.card-hover {
  color: black;
  display: block;
}

.lo-card-content:hover {
  box-shadow: 0px 2px 5px 0px rgb(81 81 81 / 41%);
  color: black;
  cursor: pointer;
}

.lo1-content-img {
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
}
/* .d-block {
	display: inline-block !important;
} */

hr {
  margin-top: 1px;
  margin-bottom: 1px;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 1444px) {
  .f-18 {
    font-size: 18px;
  }
}

@media only screen and (max-device-width: 1024px) {
  .f-18 {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .text-left {
    text-align: left !important;
  }
  .broder-left {
    border-left: 0px solid gray;
  }
  .border-top {
    border-top: 1px solid rgba(128, 128, 128, 0.354);
    padding: 12px 0;
  }
  .lo-card-img {
    width: 60px;
    height: 60px;
  }
  .lo-profile-content {
    position: relative;
    padding: 16px;
    font-size: 14px;
  }
}

@media (min-width: 544px) {
  .lo-card-img {
    width: 60px;
    height: 60px;
  }
}
@media (max-width: 425px) {
  .auto-hr {
    width: 30%;
    display: inline-block;
  }
}
