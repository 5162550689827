.carousel-caption {
  width: 70%;
  text-align: left;
}

.carousel-caption > h2,
.carousel-caption > p {
  color: white;
  text-align: left;
}

.carousel-item {
  height: 765px;
  object-fit: cover;
}

.image-caption-banner {
  width: 600px;
  height: 500px;
  object-fit: contain;
}

.person {
  padding: 1% 10%;
}

.p-c3 {
  padding: 2rem 11rem 2rem 11rem;
}

.partner-iux {
  color: white;
  padding: 130pt 93pt 0pt 0pt;
}

@media screen and (max-width: 1024px) {
  .carousel-item {
    height: 500px;
    object-fit: cover;
  }

  .image-caption-banner {
    width: 390px !important;
    height: 360px !important;
  }

  .carousel-caption {
    top: 8% !important;
    right: 0;
    bottom: 0px;
    left: 0% !important;
  }
}

@media screen and (max-width: 768px) {
  .carousel-item {
    height: 700px;
    object-fit: cover;
  }

  .carousel-caption {
    top: 5% !important;
    right: 0;
    bottom: 0px;
    left: 0% !important;
  }

  .image-caption-banner {
    width: 240px !important;
    height: 200px !important;
  }

  .p-c3 {
    padding: 3px 3px 29px 3px;
  }

  .partner-iux {
    color: white;
    padding: 15px;
  }
  .left-respon-h6 {
    text-align: left;
  }
}

@media screen and (max-width: 425px) {

  .carousel-caption {
    top: 50px !important;
    right: 0;
    bottom: 0px;
    left: 0 !important;
  }

  .carousel-item {
    height: 820px;
    object-fit: cover;
  }
}
