.content-animation {
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  //   height: 160px;
  overflow: hidden;
  font-size: 32px;
  line-height: 50px;
  color: #ecf0f1;
  @media (max-width: 480px) {
    font-size: 24px;
    width: 100%;
  }
  &-container {
    font-weight: 600;
    overflow: hidden;
    height: 40px;
    // padding: 0 40px;

    &:after,
    &:before {
      position: absolute;
      top: 0;

      color: #16a085;
      font-size: 42px;
      line-height: 50px;

      -webkit-animation-name: opacity;
      -webkit-animation-duration: 2s;
      -webkit-animation-iteration-count: infinite;
      animation-name: opacity;
      animation-duration: 2s;
      animation-iteration-count: infinite;
    }

    &-text {
      display: inline;
      float: left;
      margin: 0;
    }

    &-list {
      margin-top: 0;
      //   padding-left: 110px;
      text-align: left;
      list-style: none;

      -webkit-animation-name: change;
      -webkit-animation-duration: 20s;
      -webkit-animation-iteration-count: infinite;
      animation-name: change;
      animation-duration: 20s;
      animation-iteration-count: infinite;

      &-item {
        line-height: 50px;
        margin: 0;
        @media (max-width: 480px) {
          text-align: center;
        }
      }
    }
  }
}

@-webkit-keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

// @-webkit-keyframes change {
//   0%,
//   12.66%,
//   100% {
//     transform: translate3d(0, 0, 0);
//   }
//   16.66%,
//   29.32% {
//     transform: translate3d(0, -25%, 0);
//   }
//   33.32%,
//   45.98% {
//     transform: translate3d(0, -50%, 0);
//   }
//   49.98%,
//   62.64% {
//     transform: translate3d(0, -75%, 0);
//   }
//   66.64%,
//   79.3% {
//     transform: translate3d(0, -50%, 0);
//   }
//   83.3%,
//   95.96% {
//     transform: translate3d(0, -25%, 0);
//   }
// }

@-o-keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

// @-o-keyframes change {
//   0%,
//   12.66%,
//   100% {
//     transform: translate3d(0, 0, 0);
//   }
//   16.66%,
//   29.32% {
//     transform: translate3d(0, -25%, 0);
//   }
//   33.32%,
//   45.98% {
//     transform: translate3d(0, -50%, 0);
//   }
//   49.98%,
//   62.64% {
//     transform: translate3d(0, -75%, 0);
//   }
//   66.64%,
//   79.3% {
//     transform: translate3d(0, -50%, 0);
//   }
//   83.3%,
//   95.96% {
//     transform: translate3d(0, -25%, 0);
//   }
// }

@-moz-keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

// @-moz-keyframes change {
//   0%,
//   12.66%,
//   100% {
//     transform: translate3d(0, 0, 0);
//   }
//   16.66%,
//   29.32% {
//     transform: translate3d(0, -25%, 0);
//   }
//   33.32%,
//   45.98% {
//     transform: translate3d(0, -50%, 0);
//   }
//   49.98%,
//   62.64% {
//     transform: translate3d(0, -75%, 0);
//   }
//   66.64%,
//   79.3% {
//     transform: translate3d(0, -50%, 0);
//   }
//   83.3%,
//   95.96% {
//     transform: translate3d(0, -25%, 0);
//   }
// }
@-webkit-keyframes change {
  0%,
  7.5%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  17.5%,
  24% {
    transform: translate3d(0, -20%, 0);
  }
  27.5%,
  34% {
    transform: translate3d(0, -40%, 0);
  }
  37.5%,
  44% {
    transform: translate3d(0, -60%, 0);
  }
  47.5%,
  54% {
    transform: translate3d(0, -80%, 0);
  }
  57.5%,
  74% {
    transform: translate3d(0, -60%, 0);
  }
  75.5%,
  84% {
    transform: translate3d(0, -40%, 0);
  }
  92.5%,
  100% {
    transform: translate3d(0, -20%, 0);
  }
}
@-o-keyframes change {
  0%,
  7.5%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  17.5%,
  24% {
    transform: translate3d(0, -20%, 0);
  }
  27.5%,
  34% {
    transform: translate3d(0, -40%, 0);
  }
  37.5%,
  44% {
    transform: translate3d(0, -60%, 0);
  }
  47.5%,
  54% {
    transform: translate3d(0, -80%, 0);
  }
  57.5%,
  74% {
    transform: translate3d(0, -60%, 0);
  }
  75.5%,
  84% {
    transform: translate3d(0, -40%, 0);
  }
  92.5%,
  100% {
    transform: translate3d(0, -20%, 0);
  }
}
@-moz-keyframes change {
  0%,
  7.5%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  17.5%,
  24% {
    transform: translate3d(0, -20%, 0);
  }
  27.5%,
  34% {
    transform: translate3d(0, -40%, 0);
  }
  37.5%,
  44% {
    transform: translate3d(0, -60%, 0);
  }
  47.5%,
  54% {
    transform: translate3d(0, -80%, 0);
  }
  57.5%,
  74% {
    transform: translate3d(0, -60%, 0);
  }
  75.5%,
  84% {
    transform: translate3d(0, -40%, 0);
  }
  92.5%,
  100% {
    transform: translate3d(0, -20%, 0);
  }
}
@keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes change {
  0%,
  7.5%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  12.07%,
  19.57% {
    transform: translate3d(0, -20%, 0);
  }
  24.14%,
  31.64% {
    transform: translate3d(0, -40%, 0);
  }
  36.21%,
  43.71% {
    transform: translate3d(0, -60%, 0);
  }
  48.28%,
  55.78% {
    transform: translate3d(0, -80%, 0);
  }
  60.35%,
  67.85% {
    transform: translate3d(0, -60%, 0);
  }
  72.42%,
  79.92% {
    transform: translate3d(0, -40%, 0);
  }
  84.49%,
  92.5% {
    transform: translate3d(0, -20%, 0);
  }
}

// 6 is the number of animation.
// Here, there are 4 lines :

// 1 to 2
// 2 to 3
// 3 to 4
// 4 to 3
// 3 to 2
// 2 to 1
// 4 to 5
// 5 to 4
// 60  +  40
// 6x + 6y = 100 (100% duration)

// HERE :
// y = 4 -> Animation between two lines
// x = 12.66 -> Time spent on a line

// You can define a value and calculate the other if you want change speed or the number of lines
