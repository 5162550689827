.badge-overlay {
  position: absolute;
  left: -3px;
  top: 3px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: 100;
  -webkit-transition: width 1s ease, height 1s ease;
  -moz-transition: width 1s ease, height 1s ease;
  -o-transition: width 1s ease, height 1s ease;
  transition: width 0.4s ease, height 0.4s ease;
}

.badge-custom {
  display: inline-block;
  padding: 1em 1em;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 200px;
}

.badge-overlay::before {
  content: "";
}

.top-right {
  position: absolute;
  top: -38px;
  right: -38px;
  -ms-transform: translateX(30%) translateY(0%) rotate(45deg);
  -webkit-transform: translateX(30%) translateY(0%) rotate(45deg);
  transform: translateX(30%) translateY(0%) rotate(45deg);
  -ms-transform-origin: top left;
  -webkit-transform-origin: top left;
  transform-origin: top left;
  background: #fbab13;
  color: #fff;
}

.btn-primary-iux {
  background-color: #009eff !important;
  border: 1px solid #009eff !important;
  color: #fff !important;
  border-radius: 4px;
  height: 40px;
}
.btn-yellow-new-iux {
  background-color: #dcbe59 !important;
  border: 1px solid #dcbe59 !important;
  color: #fff !important;
  border-radius: 10px;
  height: 40px;
}
.btn-primary-new-iux {
  background-color: #1cb894 !important;
  border: 1px solid #1cb894 !important;
  color: #fff !important;
  border-radius: 10px;
  height: 40px;
}
.btn-cancel-new-iux {
  background-color: #fff !important;
  border: 1px solid #000 !important;
  color: #000 !important;
  border-radius: 10px;
  height: 40px;
}
.btn-primary-new-iux:hover {
  background-color: transparent !important;
  border: 1px solid #1cb894 !important;
  color: #1cb894 !important;
  border-radius: 10px;
  height: 40px;
}
.btn-primary-new-iux:disabled,.btn-primary-new-iux[disabled] {
  background-color: #f0f2f5 !important;
  border: 1px solid #f0f2f5 !important;
  color: #8D8D8D !important;
  border-radius: 10px;
  height: 40px;
}

.btn-primary-iux-deposit {
  background-color: #28a141 !important;
  border: 1px solid #28a141 !important;
  color: #fff !important;
  border-radius: 10px;
  height: 40px;
}
.ant-tabs-nav .ant-tabs-tab:hover {
  color: #1cb880 !important;
}
.btn-disabled-iux {
  background-color: #a3a3a3 !important;
  border: 1px solid #a3a3a3 !important;
  color: #fff !important;
  border-radius: 10px;
  height: 40px;
}
.btn-gray-iux-outline {
  /* background-color: #a3a3a3 !important; */
  border: 1px solid #a3a3a3 !important;
  color: #fff !important;
  border-radius: 10px;
  height: 40px;
}

.btn-outline-iux {
  background-color: transparent;
  border: 1px solid #1cb880 !important;
  color: #1cb880 !important;
  border-radius: 10px;
  height: 40px;
}
.btn-outline-iux:hover {
  background-color: #1cb880;
  border: 1px solid #1cb880 !important;
  color: #fff !important;
  border-radius: 10px;
  height: 40px;
}

.ant-tabs-ink-bar {
  height: 4px;
  background-color: #1cb894 !important;
}

.ant-tabs-nav .ant-tabs-tab-active {
  font-weight: 700;
  color: #000 !important;
}
.ant-steps-item-process .ant-steps-item-icon {
  background: #1CB894 !important;
  border-color: #1CB894 !important;
}
.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #1CB894 !important;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #1CB894 !important;
}
/* .botton-add-account:hover {
  display: inline-block;
  position: relative;
  box-shadow: "0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .12), 0 1px 5px 0 rgba(0, 0, 0, .13)";
  border-radius: 10px;
  padding: 10px 20px;
  border: 2px solid #03588c;
  cursor: pointer;
  transform: scale(1.1, 1.1);
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.082);
} */

.ant-statistic-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
}

.ant-btn-icon-only.ant-btn-lg {
  width: 40px;
  height: 40px;
  padding: 4.9px 0;
  font-size: 18px;
  border-radius: 40px;
  background-color: white;
  border: 0px solid;
}

/* WALLET */

.ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 16px 16px 0 0;
}
.user .ant-modal-header {
  padding: 24px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 20px 20px 0 0;
  text-align: center;
}



.button-cancle-createAccount {
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #cecfd2;
  padding: 3px 56px;
}
.button-submit-createAccount {
  background-color: #03588c;
  color: #fff;
  border-radius: 6px;
  border: 1px solid #03588c;
  padding: 3px 56px;
}
.user-change .icon-user .ant-input:placeholder-shown {
  text-overflow: ellipsis;
  background-color: #fff;
  color: #000;
  border: unset;
  height: auto;
}
.user-change .ant-input:placeholder-shown {
  text-overflow: ellipsis;
  background-color: #fff;
  color: #000;
  border: 1px solid #d9d9d9;
}
.user-change .user-password .ant-input {
  text-overflow: ellipsis;
  background-color: #fff;
  color: #000;
  border: 0;
  height: 38px;
}
.user-change .user-password .ant-input:placeholder-shown {
  text-overflow: ellipsis;
  background-color: #fff;
  color: #000;
  border: 0;
  height: 38px;
}
.user.ant-input {
  text-overflow: ellipsis;
  background-color: #fff;
  border: 1px solid #3c3c3c;
  color: #000;
}
.ant-input:placeholder-shown {
  text-overflow: ellipsis;
  background-color: #242424;
  border: 1px solid #3c3c3c;
  color: #a0a0a0;
}
.ant-input {
  text-overflow: ellipsis;
  background-color: #242424;
  border: 1px solid #3c3c3c;
  color: #a0a0a0;
}

.iux-outline {
  margin-top: "137px";
  font-size: "35px";
  color: "#adadade0";
}

.ant-drawer-body {
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 0px;
  overflow: auto;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
  background-color: #8d8f900d;
  height: fit-content;
}

/* .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fff;
  color: #001529;
  border-radius: 6px 0px 0px 6px;
  font-weight: 700;
  font-family: sans-serif;
} */

.ant-layout-sider-light {
  background: #fff;
  margin-top: 20px;
  text-transform: uppercase !important;
}

.iux-borderBottom {
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 16px;
  padding-right: 10px;
}

.iux-borderBottomx {
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 16px;
  padding-right: 10px;
}

.img-irp {
  width: 24px;
  height: 24px;
}
.border-lotback-exchange {
  border: 1px solid #f2f2f2;
  padding: 16px;
  border-radius: 12px;
  min-width: 48%;
  margin: 1%;
}

.border-left {
  border-left: "1px solid #80808070";
}



.fw-700 {
  font-weight: 700 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-300 {
  font-weight: 300 !important;
}
.fw-400 {
  font-weight: 400 !important;
}


.bg-draweriux {
  background-color: #000 !important;
  color: #fff !important;
}

.trading-account-indrawr {
  padding: 1px 4px;
  background-color: #dcbf24;
  border-radius: 6px;
}
#menu-iuxdrawer {
  border: none;
  background-color: #000 !important;
  color: #fff !important;
  height: 100%;
  overflow: auto;
}

.border-tbWallet {
  border-bottom: 1px solid #ffffff1a;
  border-top: 1px solid #ffffff1a;
  padding: 0.2rem 0px;
}
.border-tbWallet-landing {
  border-bottom: 1px solid #ffffff1a;
  border-top: 1px solid #ffffff1a;
  padding: 0.5rem 0px;
}
.border-BottomWallet {
  border-bottom: 1px solid #ffffff1a;
  padding: 1rem 0px;
}

.status-irp {
  padding: 12px 10px;
  font-size: 15px;
  /* font-weight: 700; */
  /* font-family: sans-serif; */
  text-align: right;
  /* color: rgb(1, 98, 172); */
  padding-left: 10px;
}

.status-irp-1 {
  
  /* font-weight: bold; */
  /* font-size: 1.125rem; */
  text-align: left;
  display: flex;
}

.lotback-point-text {
  font-size: 10px;
  font-family: sans-serif;
  font-weight: 700;
  color: rgb(189, 186, 186);
  margin-top: 20px;
}
.ant-layout-sider {
  position: relative;
  min-width: 0;
  background: #001529;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  min-width: 100px !important;
  max-width: 100px !important;
}

.ant-modal-wrap {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}

.text-content-irp {
  padding-bottom: 3px;
  font-size: 14px;
}

.mb-10 {
  margin-bottom: 10px;
}

.text-header-irp {
  font-size: 23px;
}

.w-modal {
  width: 50% !important;
}

.ant-btn .anticon {
  display: inline-flex;
}

.ant-dropdown-menu {
  border-radius: 0px;
  /* top: 10px !important;
  right: 10px; */
}

.ant-card-head {
  padding: 0 14px !important;
  border-radius: 5px 5px 0px 0px;
}

.account-card {
  border: 0px solid;
  border-radius: 5px;
  display: block;
  width: 100%;
  border-radius: 20;
  box-shadow: unset;
  border: 1px solid #f2f2f2;
  transition: none;
  margin: 0px 0px 20px 0px !important;
}

.ant-statistic-content {
  font-size: 18px;
}

.anticon svg {
  display: block;
}

.col-xl-3-custom {
  flex: 0 0 0%;
}

.ant-result-extra {
  margin-top: 10px;
  text-align: center;
}

.ant-result-icon {
  margin-bottom: 7px;
  text-align: center;
}

.ant-result-title {
  color: rgba(83, 83, 82, 0.85);
  font-size: 24px;
  line-height: 1.8;
  text-align: center;
}

.ant-result-subtitle {
  color: rgba(59, 59, 59, 0.85);
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
}

.input-selected {
  max-width: 100%;
  flex: auto;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

.ant-input-suffix {
  margin-left: 4px;
  display: flex;
  align-items: center;
}

.show-responsive {
  display: none !important;
}

.hide-responsive {
  display: inline;
}

.ant-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
  border-radius: 10px;
}

.real-wallet {
  border-radius: 4px;
  background-color: #174b77;
  color: #fff;
  font-size: 12px;
  margin: 4px 2px;
  width: 30px;
  height: 20px;
  display: flex;
}

.btn-walletIUXdeposit {
  background-color: #4caf50 !important;
  color: #fff !important;
  border: 1px solid #4caf50 !important;
  margin: auto 1rem;
  text-transform: uppercase;
  padding: 0 1.4rem !important;
}
/* .btn-walletIUXdeposit:hover {
  background-color: #4caf50;
  display: inline-block;
  padding: 6px 26px;
  border-radius: 4px;
  color: #fff;
  border: 1px solid;
  margin: 0px 5px;
  transform: scale(1.1, 1.1);
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
} */
.btn-primary-new-outline {
  background-color: #fff !important;
  border: 1px solid #1cb894 !important;
  color: #1cb894 !important;
  border-radius: 10px;
  height: 40px;
}
.btn-primary-new-outline-not-hover {
  background-color: #fff !important;
  border: 1px solid #393939 !important;
  color: #fff !important;
  border-radius: 10px;
  height: 40px;
}
.btn-dark-new-outline {
  background-color: #000 !important;
  border: 1px solid #393939 !important;
  color: #fff !important;
  border-radius: 10px;
  height: 40px;
}
.btn-dark-new-outline:hover {
  background-color: #000 !important;
  border: 1px solid #1cb894 !important;
  color: #1cb894 !important;
  border-radius: 10px;
  height: 40px;
}
.btn-dark-new-outline-not-hover {
  background-color: #1cb894 !important;
  border: 1px solid #1cb894 !important;
  color: #1cb894 !important;
  border-radius: 10px;
  height: 40px;
}
.btn-orange-new-outline {
  background-color: #fff !important;
  border: 1px solid #FE9F01 !important;
  color: #FE9F01 !important;
  border-radius: 10px;
  height: 40px;
}
.btn-orange-new-outline:hover {
  background-color: #FE9F01 !important;
  border: 1px solid #FE9F01 !important;
  color: #fff !important;
  border-radius: 10px;
  height: 40px;
}
/* .btn-primary-new-outline-not-hover:hover {
  background-color: #fff !important;
  border: 1px solid #1cb894 !important;
  color: #1cb894 !important;
  border-radius: 8px;
  height: 40px;
} */
.btn-primary-new-outline:hover {
  background-color: #1cb894 !important;
  border: 1px solid #1cb894 !important;
  color: #fff !important;
  border-radius: 10px;
  height: 40px;
}
.btn-primary-new-outline:hover path {
  fill: #fff;
}
.btn-primary-outline {
  background-color: #9dd3f400 !important;
  border: 1px solid #185f91 !important;
  color: #185f91 !important;
  border-radius: 4px;
  height: 40px;
}
.btn-yellow-outline {
  background-color: #9dd3f400 !important;
  border: 1px solid #dcbe59 !important;
  color: #dcbe59 !important;
  border-radius: 10px;
  height: 40px;
}
.btn-success-outline {
  background-color: #9dd3f400 !important;
  border: 1px solid #1cb894 !important;
  color: #1cb894 !important;
  border-radius: 10px;
  height: 40px;
}

.btn-success-iux-custom {
  background-color: #1cb894 !important;
  border: 1px solid #1cb894 !important;
  color: #fff !important;
  border-radius: 10px;
  height: auto;
}
/* .btn-primary-outline:hover {
  background-color: #0aff1400;
  display: inline-block;
  padding: 6px 26px;
  border-radius: 4px;
  border: 1px solid;
  margin: 0px 5px;
  transform: scale(1.1, 1.1);
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
} */

.real-walletmt4 {
  border-radius: 4px;
  background-color: #bbbbbb;
  color: #fff;
  font-size: 12px;
  margin: 4px 2px;
  width: 30px;
  height: 20px;
  display: flex;
}

.real-wallet-mt4-type {
  border-radius: 4px;
  background-color: #06a0ff;
  color: #fff;
  font-size: 12px;
  margin: 4px 2px;
  width: 30px;
  height: 20px;
  display: flex;
}

.real-wallet-mt4-type-stdb {
  border-radius: 4px;
  background-color: #0162ac;
  color: #fff;
  font-size: 12px;
  margin: 4px 2px;
  width: 30px;
  height: 20px;
  display: flex;
}

.real-wallet-mt4-type-enc {
  border-radius: 4px;
  background-color: #28a141;
  color: #fff;
  font-size: 12px;
  margin: 4px 2px;
  width: 30px;
  height: 20px;
  display: flex;
}

.real-wallet-mt4-type-pro {
  border-radius: 4px;
  background-color: #1cb894;
  color: #fff;
  font-size: 12px;
  width: 30px;
  height: 20px;
  margin: 4px 2px;
  display: flex;
}

.real-wallet-mt4-type-demo {
  border-radius: 4px;
  background-color: #ff6c00;
  color: #fff;
  font-size: 12px;
  margin: 4px 2px;
  width: 41px;
  height: 20px;
  display: flex;
}

.ant-alert-with-description.ant-alert-no-icon {
  padding: 15px 15px;
  margin-bottom: 30px;
}

.bonus-mybonus {
  padding-top: 18px;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 700;
  padding-left: 10px;
}

.bonus-styleRemain {
  padding-left: 26px !important;
  padding-right: 3px;
}

.flex-bonusRemain {
  font-size: 20px;
  font-family: sans-serif;
  font-weight: 700;
  /* display: flex; */
  min-width: 466px;
}

.ant-btn-round.ant-btn-lg {
  height: 40px;
  padding: 6.4px 20px;
  font-size: 16px;
  border-radius: 7px;
  width: 100%;
}

.btn-login-landingpage {
  width: 100% !important;
  height: 32px !important;
  padding: 0 !important;
  font-size: 14px !important;
  border-radius: 2px !important;
}

.text-header-account-wallet {
  font-weight: 700;
  font-family: sans-serif;
  font-size: 24px;
}

/* .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	position: relative;
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	width: 100%;
	height: 36px;
	padding: 0 11px;
} */

.bg-navwallet {
  position: relative;
  background-color: #0e3552 !important;
  border: 0px solid #d9d9d9 !important;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  height: 36px;
  padding: 0 11px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

.ant-modal-close-x {
  width: 38px;
  height: 20px;
  font-size: 8px;
  font-style: normal;
  line-height: 32px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  display: none;
}
.user .ant-modal-close-x {
  width: 40px;
  height: 40px;
  font-size: 16px;
  font-style: normal;
  line-height: 32px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  display: block;
}

.onHoverLang {
  background-color: #fff !important;
}
.onHoverLang:hover {
  background-color: #cccccc !important;
}

.logo-autoNav {
  text-align: left;
}

.border-right-bonus {
  border-right: #ccc 1px dashed;
}

.border-Left-bonus {
  border-right: #ccc 1px solid;
}

.max-width-layout {
  /* max-width: 100%; */
  padding: "24px 0px";
  position: "relative";
}

.border-left-content-top {
  border-left: 1px solid #80808066;
}

.botton-get-bonus-cash {
  background: #04a204;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px 0px;
  display: inline-block;
  padding: 10px 20px;
  /* border-radius: 5px; */
  cursor: pointer;
}
.botton-get-bonus-cash:hover {
  background: #04a204;
  color: white;
  box-shadow: rgb(0 0 0 / 42%) 0px 3px 6px 0px;
  display: inline-block;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.right-layout-card {
  float: left;
  margin-top: 137px;
  font-size: 35px;
  margin-right: 17px;
  color: #7e7e7e83;
  position: absolute;
}

.card-irp-3 {
  min-width: 286px;
  min-height: 178px;
  max-height: 178px;
  /* margin-left: 36px; */
}

.card-irp-style {
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px 0px; */
  border-radius: 12px;
  padding: 0px;
  overflow: hidden;
  padding: 24px;
  /* min-height: 200px;
  max-height: 200px; */
  border: 1px solid #D9D9D9;
  
}
.card-irp-style-active {
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px 0px; */
  border-radius: 12px;
  padding: 0px;
  overflow: hidden;
  padding: 24px;
  /* min-height: 200px;
  max-height: 200px; */
  border: 1px solid #49C6A9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}

.pd-5 {
  padding-bottom: 5px;
  text-align: left;
}

.ant-form-item {
  flex-wrap: wrap;
  margin-bottom: 16px;
  white-space: initial !important;
}
.from-transfer {
  min-width: 100% !important;
  text-align: left !important;
}

.ant-form-horizontal .ant-form-item-label {
  -ms-flex-positive: 0;
  flex-grow: 0;
  min-width: 100%;
  text-align: left;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #3c3c3c;
  border-radius: 4px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  padding: 0 11px;
}

.ant-select-selector {
  background-color: #242424 !important;
  text-align: left;
  color: #a0a0a0;
  border: 1px solid #3c3c3c;
}

/* .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: #ffffff;
} */
.user .ant-input-affix-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 0px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #ffffff;
  background-image: none;
  /* border: 1px solid #d9d9d9; */
  border-radius: 8px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.user-change .ant-input-affix-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #ffffff;
  background-image: none;
  /* border: 1px solid #d9d9d9; */
  border-radius: 8px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.ant-input-affix-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #ccc;
}

.ant-select-focused.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

.justify-center {
  justify-content: center !important;
}

.ant-input-affix-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  display: inline-flex;
}

.form-respon-creditcard {
  display: block !important;
  margin-top: 16px;
}

.input-none-boxshadow {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  box-shadow: none !important;
  font-size: 16px;
}

.img-visamaster {
  max-width: 125px;
}

.text-center-mb {
  margin-bottom: 24px;
}

.ant-card-head-title {
  display: flex;
  -ms-flex: 1 1;
  flex: 1 1;
  padding: 14px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.hidden-overflow {
  overflow: hidden;
}
.b-s-faq {
  font-size: 24px;
  padding: 4px 0px 0px 10px;
}
.img-bonus-cash {
  max-width: 76px;
  margin-top: 10px;
}

.lotback-logo-curren {
  width: 24px;
  height: 24px;
}

.botton-add-accountSub {
  position: relative;
  border-radius: 2px;
  padding: 6px 16px;
  border: 2px solid #03588c;
  cursor: pointer;
  display: none;
  margin: 24px 4rem;
  background-color: #03588c;
}

.font-accountHeader {
  margin: 4px;
  font-size: 14px;
}

.font-accountHeaderDemo {
  margin: 4px;
  font-size: 12px;
}

.minandmax-width {
  min-width: 303px;
  max-width: 303px;
}

.text-remainBonus {
  color: #afafaf;
}

.transfer-wallet-dashboard1 {
  margin: 0px 0px 6px;
  background: rgb(255, 255, 255);
  padding: 24px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px 0px;
  min-width: 350px;
  max-width: 350px;
}

.history-wallet-dashboard1 {
  margin: 0px 0px 6px;
  background: rgb(255, 255, 255);
  padding: 20px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px 0px;
  margin-left: 6px;
  /* overflow: scroll; */
}

.btn-wallet-bonusreamin {
  background-color: #4caf50;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 4px;
  color: #fff;
  border: 1px solid;
  margin: 0px 5px;
  text-transform: uppercase;
  font-size: 12px;
  white-space: pre;
}

.iux-col-md {
  position: relative;
  width: 90%;
  padding-right: 15px;
  /* padding-left: 15px; */
  /* margin-top: 16px; */
}

.border-bottom-dashed {
  border-bottom: dashed 1px #7b7b7b73;
}

.font-sprade {
  font-size: 156px;
  font-family: sans-serif;
  font-weight: 700;
}

.pips-ads {
  font-size: 24px;
  padding-top: 120px;
}

.min-max-auto {
  min-width: 50% !important;
}

.ads-iux-stdx {
  width: 673px !important;
  /* background: url(/public/assets/images/ads.png) !important; */
}

/* .ant-modal-body {
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
} */

/*  */

.header-ads {
  font-size: 16px;
}

.header-ads-stdx {
  font-size: 40px;
  font-family: sans-serif;
  font-weight: 700;
}

.x-close {
  position: absolute;
  display: inline;
  right: 20px;
  font-size: 30px;
  cursor: pointer;
  top: -17px;
}

.img-ads {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

.text-bold {
  font-weight: 700;
}
.text-semibold {
  font-weight: 500;
}

.new-tab {
  position: absolute;
  text-align: center;
  font-size: 12px;
  color: #ffd800;
  padding: 0px 10px;
  background-color: #e82e2e;
  border-radius: 10px;
  top: 1px;
  right: -26px;
  align-items: center;
  display: flex;
}

.irp-respon {
  display: block;
  float: left !important;
  margin-top: 24px;
  border-right: 1px dashed #80808096;
  padding-right: 13px;
  margin-bottom: 10px;
}

.col-md-lotback {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.lotback-curren-status {
  padding: 0px 0px 0px 10px;
  font-size: 16px;
}

/* PLATFORM */

.platform-border-col {
  border-radius: 8px;
  border: 1px solid #f2f2f2;
  /* box-shadow: 0 0 20px #c7c7c76e; */
}

.platform-download {
  padding: 18px;
  background-color: #1cb894;
  display: flex;
  color: #fff !important;
  text-transform: uppercase;
  font-family: sans-serif;
  font-weight: 600;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
}

.platform-download-disabled {
  padding: 18px;
  background-color: #616161;
  display: flex;
  color: #fff !important;
  text-transform: uppercase;
  font-family: sans-serif;
  font-weight: 600;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
}

.platform-img-metatrader {
  /* height: 192px; */
  width: 100%;
  padding: 12px;
}

.platform-col-md {
  position: relative;
  width: 280px !important;
}

.platform-dflex-auto {
  display: flex !important;
}

.drawer-item {
  padding-top: 10px;
  padding-left: 24px;
  padding-bottom: 8px;
}

@media (max-width: 1440px) {
  /* .ant-modal{
    top: 50px
  } */
}

@media (min-width: 1200px) {
  .col-xl-3-custom {
    flex: 0 0 0%;
    max-width: 25%;
    margin: 4px;
  }

  .ant-card-head-title {
    display: inline-block;
    -ms-flex: 1 1;
    flex: 1 1;
    padding: 14px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
  }
}

.account-card .ant-card-extra {
  display: flex;
  align-items: center;
}

.ant-popover {
  z-index: 5 !important;
}

/* .ant-menu {
	background-color: #00000000;
} */

.top-account-card {
  background-color: #0162ac !important;
  padding: 16px !important;
}

.hide-desktop {
  display: none !important;
}

.d-noneDes {
  display: none;
}

@media only screen and (max-device-width: 1024px) {
  .d-flex-auto-lotback {
    display: flex !important;
  }
  .flex-lotback {
    flex: 0 0 80%;
  }

  .col-md-lotback {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    flex: 0 0 50%;
    max-width: auto;
  }

  .status-irp {
    padding: 12px 10px;
    font-size: 15px;
    /* font-weight: 700; */
    /* font-family: sans-serif; */
    text-align: left;
    /* color: rgb(1, 98, 172); */
    padding-left: 10px;
  }

  .status-irp-1 {

    /* font-weight: bold; */
    /* font-size: 1.125rem; */
    text-align: left;
    display: flex;
  }

  .border-left-content-top {
    border-left: 0px solid #80808066;
  }
  .card-irp-style-active {
    margin-top: 16px;
  }
  .card-irp-style {
    border-radius: 12px;
  
    padding: 24px;
    overflow: hidden;
   
    margin-top: 16px;
  }

  .minandmax-width {
    min-width: 344px;
    max-width: 344px;
  }

  .account-card {
    border: 0px solid;
    border-radius: 5px;
    display: block;
    width: 100%;
    border-radius: 20;
    box-shadow: unset;
    border: 1px solid #f2f2f2;
    transition: none;
    margin: 0px 0px 20px 0px !important;
  }

  .btn-walletIUXdeposit {
    background-color: #4caf50;
    display: inline-block;
    padding: 6px 8px;
    border-radius: 4px;
    color: #fff;
    border: 1px solid;
    margin: 0px 5px;
    text-transform: uppercase;
  }
  .show-responsive {
    display: inline !important;
  }
  .hide-responsive {
    display: none;
  }

  /* .real-wallet {
    padding: 3px 2px;
    border-radius: 2px;
    background-color: #174b77;
    color: #fff;
    font-size: 12px;
    margin: 4px;
  } */
  /* .real-walletmt4 {
    padding: 3px 2px;
    border-radius: 4px;
    background-color: #bbbbbb;
    color: #fff;
    font-size: 12px;
    margin: 4px;
  } */

  .font-accountHeader {
    margin: 4px;
    font-size: 14px;
  }

  .border-right-bonus {
    border-right: #ccc 0px dashed;
  }

  .bonus-styleRemain {
    padding-left: 0px !important;
    padding-right: 3px;
  }

  .flex-bonusRemain {
    font-size: 20px;
    font-family: sans-serif;
    font-weight: 700;
    /* display: flex; */
    min-width: 525px;
  }
  .hide-ipad {
    display: block !important;
  }

  .d-noneDes {
    display: block;
  }

  .transfer-wallet-dashboard1 {
    margin: 0px 0px 6px;
    background: rgb(255, 255, 255);
    padding: 24px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px 0px;
    min-width: 100% !important;
    max-width: 100% !important;
  }

  .history-wallet-dashboard1 {
    margin: 0px 0px 6px;
    background: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px 0px;
    margin-left: 0px;
    /* overflow: scroll; */
  }
}

@media screen and (max-width: 1024px) {
  .w-modal {
    width: 75% !important;
  }

  .button_partner_respon {
    padding: 0% 10%;
    margin-top: 16px;
    margin-left: 75px;
  }

  .border-right-bonus {
    opacity: 1;
    border-bottom: 1px dashed #c7c7c7;
  }

  .iux-col-md {
    position: relative;
    width: 80%;
    padding-right: 15px;
    /* padding-left: 15px; */
    margin-top: 16px;
  }
}
@media screen and (max-width: 768px) {
  .platform-img-metatrader {
    height: auto;
    width: 180px;
    padding: 12px;
  }
  .platform-col-md {
    position: relative;
    width: 100%;
    margin: 16px auto;
  }
  .platform-dflex-auto {
    display: block !important;
  }

  .iux-col-md {
    position: relative;
    width: 70%;
    padding-right: 15px;
    /* padding-left: 15px; */
    margin-top: 16px;
  }

  .transfer-wallet-dashboard1 {
    margin: 0px 0px 6px;
    background: rgb(255, 255, 255);
    padding: 24px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px 0px;
    min-width: 100% !important;
    max-width: 100% !important;
  }

  .hide-create {
    display: none !important;
  }

  .hide-desktop {
    display: inline !important;
  }

  .minandmax-width {
    min-width: 100%;
    max-width: 100%;
  }

  .bonus-styleRemain {
    padding-left: 0px !important;
    padding-right: 3px;
  }

  .flex-bonusRemain {
    font-size: 20px;
    font-family: sans-serif;
    font-weight: 700;
    /* display: flex; */
    min-width: 270px;
  }
  .min300-responsive {
    min-width: 300px;
  }
}
@media screen and (max-width: 767px) {
  .col-xl-3-custom {
    flex: 0 0 0%;
    margin: 4px;
  }

  .w-modal {
    width: 100% !important;
  }

  .desciption-create-account {
    display: none !important;
  }

  .top-account-card {
    background-color: #0162ac !important;
    padding: 16px !important;
  }

  .border-right-bonus {
    opacity: 1;
    border-bottom: 1px dashed #c7c7c7;
  }

  .max-width-layout {
    max-width: 100%;
    padding: "24px 0px";
    position: "relative";
  }

  .lotback-logo-curren {
    width: 24px;
    height: 24px;
    margin-top: 2px;
  }

  .img-bonus-cash {
    max-width: 80px;
  }
  .b-s-faq {
    font-size: 20px;
  }

  .irp-respon {
    display: block;
    float: left !important;
    margin-top: 8px;
    border-right: 1px solid #80808096;
    padding-right: 13px;
  }

  .fw-700 {
    font-weight: 700 !important;
    /* display: flex; */
    /* padding-left: 13px; */
  }



  .botton-bonus-irp {
    /* border: 1px solid rgba(0, 0, 0, 0.22); */
    margin-top: 25px;
    margin-bottom: 25px;
    /* background-color: rgba(128, 128, 128, 0.26);
        font-size: 20px;
        font-size: 17px;
        padding: 3px; */
  }


  .status-irp {
    padding: 10px 0px;
    font-size: 16px;
    /* font-weight: 700; */
    text-align: left;
    /* color: rgb(1, 98, 172); */
  }

  .min-w {
    min-width: "300";
    padding: "18px 18px";
  }



  .ant-modal-wrap {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0;
    left: 0;
    z-index: 1000;
    overflow: auto;
    outline: 0;
    -webkit-overflow-scrolling: touch;
  }

  .text-content-irp {
    padding-bottom: 3px;
    font-size: 16px;
    text-align: left;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .text-header-irp {
    font-size: 23px;
  }

  .border-left-content-top {
    border-left: 0px solid gray;
    border-top: 1px solid #80808066;
  }

  .botton-get-bonus-cash {
    background: green;
    color: white;
    width: 230px;
  }

  .card-irp-3 {
    min-width: 280px;
    margin-left: 0;
    margin-top: 16px;
  }

  .right-layout-card {
    float: left;
    margin-top: 137px;
    font-size: 35px;
    margin-right: 17px;
    color: #7e7e7e83;
    position: absolute;
    opacity: 0;
  }

  .form-respon-creditcard {
    display: inline;
  }

  .form-respon-creditcard-input {
    width: 255px;
    box-shadow: none;
  }

  .text-center-mb {
    margin-bottom: 24px;
    text-align: center;
  }

  .account-card {
    border: 0px solid;
    border-radius: 3px;
    display: inline-block;
    width: 100%;
    border-radius: 20;
    box-shadow: unset;
    border: 1px solid #f2f2f2;
    transition: none;
  }
}

@media screen and (max-width: 768px) {
  .padding-trade-program {
    padding: 26px !important;
  }
  .ant-popover-placement-bottomLeft
    > .ant-popover-content
    > .ant-popover-arrow {
    left: 180px;
  }
  .top-account-card {
    background-color: #0162ac !important;
    padding: 16px !important;
  }

  .ant-respon {
    float: left;
    /* margin-left: 16.89px; */
    text-align: left;
  }

  .ant-respon-lev {
    float: left;
    margin-left: 30.89px;
  }

  .ant-card-body {
    padding: 9px !important;
  }

  .ant-statistic-content {
    font-size: 18px;
  }

  .input-selected {
    max-width: 100%;
    flex: auto;
  }

  .border-left {
    border-left: 0px solid;
  }

  .ant-modal-header {
    padding: 16px 24px;
    color: rgba(0, 0, 0, 0.65);
    background: #fff;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 3px 3px 0 0;
  }
  .user .ant-modal-header {
    padding: 16px 24px;
    color: rgba(0, 0, 0, 0.65);
    background: #fff;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 3px 3px 0 0;
    text-align: center;
  }

  .min300-responsive {
    min-width: 168px;
  }
}
@media  (max-width: 770px)  {
  .hide-navWallet {
    display: none;
  }
}
@media (max-width: 480px) {
  .ant-modal {
    max-width: calc(100vw - 16px);
    /* margin: 0px auto !important; */
  }
  /* .menu-active {
    background-color: #d3e4eb26 !important;
    color: rgba(0, 0, 0, 0.65);
   
    font-weight: 500;
    font-family: sans-serif;
  }
  .menu-inactive {
    background-color: #d3e4eb00 !important;
    color: rgba(0, 0, 0, 0.65);

    font-weight: 500;
    font-family: sans-serif;
  } */
  /* .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #d3e4eb26;
    color: rgba(0, 0, 0, 0.65);
    border-radius: 6px 0px 0px 6px;
    font-weight: 500;
    font-family: sans-serif;
  } */

  .ant-steps-horizontal.ant-steps-label-horizontal {
    display:flex;
    flex-direction: row;
  }

  .ant-steps-item-container {
    display: inline-grid;
  }

  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
    display: initial;
    overflow: visible;
  }

  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-title {
    line-height: 32px;
    float: left;
    font-size: 12px;
    /* opacity: 0; */
    right: 2px;
    position: absolute;
    white-space: pre-line;
    text-align: center;
    line-height: 1.25rem;
    padding: 0;
  }

  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-description {
    padding-bottom: 12px;
    font-size: 0.7rem;
    margin-top: 10px;
  }

  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
    float: left;
    margin-right: 0px;
    margin-left: 21px;
  }

  .ant-result {
    padding: 48px 14px;
  }

  .show-responsive {
    display: inline !important;
  }
  .hide-responsive {
    display: none;
  }

  .minWallet-w100 {
    min-width: auto;
  }

  .flex-bonusRemain {
    font-size: 20px;
    font-family: sans-serif;
    font-weight: 700;
    min-width: 100%;
  }

  .logo-autoNav {
    text-align: left;
  }

  .botton-add-accountSub {
    position: relative;
    border-radius: 2px;
    padding: 6px 16px;
    border: 2px solid #03588c;
    cursor: pointer;
    display: block;
    margin: 24px 4rem;
    background-color: #03588c;
  }
  .ant-layout-sider {
    position: relative;
    min-width: 0;
    background: #001529;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    min-width: 0px !important;
    max-width: 0px !important;
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0;
    -webkit-transition: background 0.3s;
    transition: background 0.3s;
    font-size: 12px;
  }

  .transfer-wallet-dashboard1 {
    margin: 0px 0px 6px;
    background: rgb(255, 255, 255);
    padding: 24px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px 0px;
  }

  .text-remainBonus {
    color: #afafaf;
    font-size: 12px;
  }
}
