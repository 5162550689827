.swap-pages .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 24px !important;
  font-size: 16px;
  font-weight: 400;
  border-bottom: 1px solid #393939;
  font-family: "Inter";
  background: #1c1c1c;
  cursor: pointer;
}
.swap-pages .ant-collapse-borderless .ant-collapse-item .ant-collapse-content {
  background: #2c2b2b !important;
}
.swap-page-responsive {
  min-width: 100%;
  @media (max-width: 820px) {
    min-width: 1140px;
  }
}
.ant-collapse-borderless > .ant-collapse-item {
  border: 0 !important;
  margin-bottom: 8px;
  border-radius: 8;
}
.swap-pages .ant-collapse-borderless > .ant-collapse-item {
  border: 0 !important;
  margin-bottom: 0;
  border-radius: 0;
}
.ant-collapse-borderless {
  background-color: transparent !important;
}
